import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Skeleton } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import moment from 'moment';
import { formatNumberTCCells } from 'utils/utils';
import axios from 'axios';

const GRAPH_HEIGHT = 400

export default function CultureHistoryForm({open, onClose, showMessage, row, setData, ...props}) {
  const [history, setHistory] = useState()
  const handleClose = () => {
    onClose?.()
  }
  useEffect(() => {
    if (row?.id)
      axios.get(`/api/wetlab/tc_basic/history?id=${row.id}`).then(({data}) => setHistory(data))
    else
      setHistory(undefined)
  }, [row?.id]);
  const [visibility, setVisibility] = useState({cells: true, density: true, volume: false, passage: false})
  return (
    <Dialog open={!!open} onClose={handleClose} fullWidth maxWidth="md" {...props}>
      <DialogTitle>TC History for {row?.name}</DialogTitle>
      <DialogContent>
        {history ?
        <LineChart
          xAxis={[{ scaleType: "time", data: history.timestamp.map(x => moment(x)), valueFormatter: x => moment(x).format("ddd M/D h:mma") }]}
          series={[
            {
              label: "Cells",
              id: "cells",
              data: history.cell,
              yAxisKey: "log",
              valueFormatter: x => formatNumberTCCells(x),
              showMark: ({ index }) => history.showMark[index],
              curve: "linear",
            },
            {
              label: "Density",
              id: "density",
              data: history.cell.map((x, i) => x && history.volume[i] && 1000 * x / history.volume[i]),
              yAxisKey: "log",
              valueFormatter: x => `${formatNumberTCCells(x)} / mL`,
              showMark: ({ index }) => history.showMark[index],
              curve: "linear",
            },
            {
              label: "Volume",
              id: "volume",
              data: history.volume.map(x => x / 1000),
              yAxisKey: "linear",
              valueFormatter: x => x >= 1 ? `${x} mL` : `${1000 * x} μL`,
              showMark: ({ index }) => history.showMark[index],
              curve: "linear",
            },
            {
              label: "Passage",
              id: "passage",
              data: history.passage,
              yAxisKey: "linear",
              showMark: ({ index }) => history.showMark[index],
              curve: "linear",
            },
          ].filter(x => visibility?.[x.id])}
          yAxis={[{id: "log", scaleType: "log"}, {id: "linear", scaleType: "linear"}]}
          leftAxis="log"
          rightAxis={(visibility.volume || visibility.passage) ? "linear" : undefined}
          height={GRAPH_HEIGHT}
        /> : <Skeleton variant="rectangular" height={GRAPH_HEIGHT} />
        }
        {/* TODO: https://github.com/mui/mui-x/issues/10159 */}
        <FormControl>
          <FormLabel component="legend">Visibility</FormLabel>
          <FormGroup row>
            <FormControlLabel control={<Checkbox />} label="Cells" checked={visibility.cells} onChange={e => setVisibility({...visibility, cells: e.target.checked})} />
            <FormControlLabel control={<Checkbox />} label="Density" checked={visibility.density} onChange={e => setVisibility({...visibility, density: e.target.checked})} />
            <FormControlLabel control={<Checkbox />} label="Volume" checked={visibility.volume} onChange={e => setVisibility({...visibility, volume: e.target.checked})} />
            <FormControlLabel control={<Checkbox />} label="Passage" checked={visibility.passage} onChange={e => setVisibility({...visibility, passage: e.target.checked})} />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect, useState } from 'react';
import {
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  dialogClasses,
  DialogTitle,
  useMediaQuery,
  Grid,
  List,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import LocationPositionViewer from './LocationPositionViewer';
import LocationPickerHeader from './LocationPickerHeader';
import LocationBoxViewer from './LocationBoxViewer';
import { DEFAULT_DESKTOP_MODE_MEDIA_QUERY } from '@mui/x-date-pickers';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import axios from 'axios';

const DIALOG_WIDTH = 448
const DIALOG_HEIGHT = 542

const PickersModalDialogRoot = styled(Dialog)(({isdesktop}) => ({
  [`& .${dialogClasses.container}`]: {
    outline: 0,
  },
  [`& .${dialogClasses.paper}`]: {
    outline: 0,
    minWidth: isdesktop === "true" ? DIALOG_WIDTH : "90%",
    minHeight: DIALOG_HEIGHT,
  },
}));

export default function LocationPickerDialog({options, location_box, location_pos, view, onChangeView, onChange, onSubmit, clearable=true, samples=[]}) {
  const multiple = samples.length > 1
  const [currentSample, setCurrentSample] = useState()
  useEffect(() => {
    if (multiple)
      setCurrentSample(samples[0])
  }, [samples])
  const handleSubmit = () => {
    onChangeView(null)
    onSubmit?.()
    setWaitingToSubmit(false)
  }
  const [waitingToSubmit, setWaitingToSubmit] = useState(false)
  useEffect(() => {
    waitingToSubmit && location_pos && handleSubmit()
  }, [location_pos])
  return (
		<PickersModalDialogRoot
			open={!!view}
			onClose={() => onChangeView(null)}
			isdesktop={useMediaQuery(DEFAULT_DESKTOP_MODE_MEDIA_QUERY, { defaultMatches: true }).toString()}
		>
			<DialogTitle>
				<LocationPickerHeader
					name={location_box?.name}
					view={view}
					onChangeView={onChangeView}
				/>
			</DialogTitle>
			<DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={multiple ? 8 : 12}>
            {view === "box" && (
              <LocationBoxViewer
                options={options}
                value={location_box}
                onSelect={(new_location_box) => {
                  onChange({location_box: new_location_box})
                  if (new_location_box)
                    onChangeView("position")
                }}
              />
            )}
            {view === "position" && (
              <LocationPositionViewer
                numRows={location_box?.num_rows}
                numCols={location_box?.num_cols}
                rowsLettered={location_box?.position_rows_lettered && location_box?.position_unraveled}
                occupiedPositions={location_box?.position_occupied}
                selectedPositions={multiple ? location_pos : [location_pos]}
                onSelect={(new_location_pos) => {
                  if (!multiple) {
                    onChange({location_pos: new_location_pos})
                    if (new_location_pos)
                      setWaitingToSubmit(true)
                  }
                  else {
                    onChange({location_pos: location_pos.map((prev_location_pos, i) => i === samples.indexOf(currentSample) ? new_location_pos : prev_location_pos)})
                    setCurrentSample(() => {
                      const i = samples.indexOf(currentSample)
                      if (i < samples.length - 1)
                        return samples[i + 1]
                      else
                        return null
                    })
                  }
                }}
              />
            )}
          </Grid>
          {multiple && (
            <Grid item xs={12} sm={4}>
              <List>
                {samples.map((sample, i) => {
                  const sample_name = sample.colony_id ? `${sample.name}-${sample.colony_id}` : sample.name
                  return (
                    <ListItemButton
                      dense
                      key={sample.id}
                      selected={currentSample === sample}
                      onClick={() => setCurrentSample(sample)}
                      aria-selected={currentSample === sample}
                      aria-label={sample_name}>
                      <ListItemIcon sx={{minWidth: theme => theme.spacing(4)}} aria-label={`position ${location_pos?.[i]}`}>{location_pos?.[i]}</ListItemIcon>
                      <ListItemText>{sample_name}</ListItemText>
                    </ListItemButton>
                  )
                }
                )}
              </List>
            </Grid>
          )}
        </Grid>
			</DialogContent>
			<DialogActions>
        {clearable && <Button onClick={() => {
          const empty_pos = multiple ? [...Array(samples.length)].map(() => null) : null
          if (view === "box")
            onChange({location_box: null, location_pos: empty_pos})
          else if (view === "position")
            onChange({location_pos: empty_pos})
        }}>Clear</Button>}
        <Button onClick={handleSubmit}>Done</Button>
			</DialogActions>
		</PickersModalDialogRoot>
	)
}

export function LocationPositionPickerDialogForSampleDataGrid(props) {
  const { id, value, field, row } = props;
  const apiRef = useGridApiContext();
  const [container, setContainer] = useState()
  // TODO: this data can't be that big. just fetch it all at once.
  useEffect(() => {
    !!row.location_box && 
      axios.get(`/api/wetlab/container/getAvailablePositions?id=${row.location_box}`)
      .then(({data : container}) => setContainer(prev => ({...prev, ...container})))
  }, [row.location_box]);

  const handleValueChange = (changes) => {
    if (changes.location_box !== undefined)
      apiRef.current.setEditCellValue({ id, field: "location_box", value: changes.location_box });
    if (changes.location_pos !== undefined)
      apiRef.current.setEditCellValue({ id, field: "location_pos", value: changes.location_pos });
  };

  const handleChangeView = (newView) => {
    if (!newView)
      apiRef.current.stopCellEditMode({id, field})
  }

  return <LocationPickerDialog
    view="position"
    location_box={container}
    location_pos={value}
    options={[]}
    onChange={handleValueChange}
    onChangeView={handleChangeView}
    />
}
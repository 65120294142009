import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, CardActions, Grow, Button, Typography, CardActionArea, CircularProgress, Box } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import PassageCellsForm from 'forms/PassageCellsForm';
import { MiniprepForm } from './samples/Bacteria';
import { useNavigate } from "react-router-dom";
import WeekendIcon from '@mui/icons-material/Weekend';
import { MoveToLn2Form } from './samples/CryoBank';
import Protocol from 'protocols/Protocol';
import axios from 'axios';
import PickColoniesForm from 'forms/PickColoniesForm';

export default function Dashboard() {
  const [cards, setCards] = useState(null)
  const [showForm, setShowForm] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    const fetchCards = () => {
      axios.get("/api/wetlab/tasks/dashboard").then(({data : json}) => setCards(json)).catch(() => {});
    };
    fetchCards();
    const fetchInterval = setInterval(fetchCards, 60000);
    return () => clearInterval(fetchInterval);
  }, []);
  const handleShowForm = ({form, data}) => {
    if (form === "discard")
      axios.post("/api/wetlab/generic/discard", {id: data})
        .then(({status}) => status === 200 && setCards(prev => (
          prev.filter(x => x.action?.form !== "discard" || x.action?.data !== data)
        )))
    setShowForm({form, data})
  }
  if (cards === null)
    return (
      <Box sx={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <CircularProgress />
        <Typography color="text.secondary" sx={{ mt: 1 }} >Determining if anything needs attention</Typography>
      </Box>
    )
  else if (cards?.length === 0)
    return (
      <Box sx={{width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <WeekendIcon color="disabled" fontSize="large" />
        <Typography color="text.secondary" sx={{ mt: 1 }} >Nothing else needs attention today</Typography>
      </Box>
    )
  else
    return (
      <>
        <Grid container sx={{p: 2}} spacing={3} role="grid">
          <TransitionGroup component={null}>
            {cards.map((card, i) => 
              <Grow key={i}>
                <Grid item xs={12} sm="auto">
                  <Card sx={{minWidth: 200}} aria-label={card.title} role="gridcell">
                    <CardActionArea onClick={() => navigate(card.link)}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {card.subtitle}
                        </Typography>
                        <Typography variant="h5">
                          {card.title}
                        </Typography>
                        {card.caption?.map((line, j) => 
                          <Typography color="text.secondary" key={j}>{line}</Typography>
                        )}
                      </CardContent>
                    </CardActionArea>
                    {card.action &&
                      <CardActions>
                        <Button onClick={() => handleShowForm(card.action)}>{card.action.button_text}</Button>
                      </CardActions>
                    }
                  </Card>
                </Grid>
              </Grow>
            )}
          </TransitionGroup>
        </Grid>
        <PassageCellsForm
          open={showForm?.form === "passage"}
          row={showForm?.form === "passage" && showForm.data}
          onClose={() => setShowForm(null)}
          onCommit={(id) => setCards(prev => prev.filter(x => x.action?.form !== "passage" || x.action?.data?.id !== id))}
        />
        <PickColoniesForm
          open={showForm?.form === "pickColonies"}
          row={showForm?.form === "pickColonies" && [showForm.data]}
          onClose={() => setShowForm(null)}
          onCommit={(ids) => setCards(prev => prev.filter(x => x.action?.form !== "pickColonies" || !x.action?.data?.id || !ids.includes(x.action.data.id)))}
        />
        <MiniprepForm
          open={showForm?.form === "miniprep"}
          row={showForm?.form === "miniprep" && showForm.data}
          onClose={() => setShowForm(null)}
          onCommit={(id) => setCards(prev => prev.filter(x => x.action?.form !== "miniprep" || x.action?.data?.id !== id))}
        />
        <MoveToLn2Form
          open={showForm?.form === "cryostock_ln2"}
          row={showForm?.form === "cryostock_ln2" && showForm.data}
          onClose={() => setShowForm(null)}
          onCommit={(id) => setCards(prev => prev.filter(x => x.action?.form !== "cryostock_ln2" || x.action?.data?.id !== id))}
        />
        <Protocol
          open={showForm?.form === "protocol"}
          row={showForm?.form === "protocol" && showForm.data}
          onClose={() => setShowForm(null)}
          onCommit={(id) => setCards(prev => prev.filter(x => x.action?.form !== "protocol" || x.action?.data?.id !== id))}
        />
      </>
    )
}

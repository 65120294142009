import React from 'react';
import TableChecklist from 'components/TableChecklist';

export default function RetrieveStep({rows, ...props}) {
  return (
    <TableChecklist
      size="small"
      columns={[
        {header: "Name", props: {component: "th", scope: "row"}},
        {header: "Box"},
        {header: "Position"},
        {header: "Description"},
      ]}
      rows={rows.map( row => [row?.name, row?.container?.name, row?.location_pos, row?.description || (row?.concentration && `${row.concentration.value}${row.concentration.unit}`) || ""] )}
      {...props}
    />
  )
}

import React, { useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, Container, Tooltip } from '@mui/material';
import moment from 'moment';
import RetrieveStep from 'protocols/steps/RetrieveStep';
import MixStep from 'protocols/steps/MixStep';
import ThermocyclingStep from 'protocols/steps/ThermocyclingStep';
import axios from 'axios';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DateTimePicker } from '@mui/x-date-pickers';
import AgaroseGelStep from './steps/AgaroseGelStep';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import NanodropStep from './steps/NanodropStep';

function calculate_time_togo({data : {time_estimated, time_start}}) {
  if (!time_estimated)
    return
  const duration_to_go = time_estimated && moment.duration(moment(time_start).add(time_estimated, "seconds") - moment())
  moment.updateLocale('en', {
    relativeTime: {hh: "%d hr", mm: "%d min", ss: "%d sec"}
  })
  return (
  <Typography variant="caption">
    {moment.duration(time_estimated, "seconds").humanize({h: 72}) + (time_start ? (" • " + (duration_to_go > moment.duration(30, "seconds") ? duration_to_go.humanize({h: 72}) + " to go" : "done")) : "")}
  </Typography>
  )
}

export function FinishButton({onClick}) {
  const [datetime_finished, setDatetimeFinished] = useState()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setDatetimeFinished(moment())
    setOpen(true)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setOpen(false)
    handleFinish()
  }
  const handleCancel = () => {
    setDatetimeFinished(undefined)
    setOpen(false)
  }
  const handleFinish = () => {
    onClick(datetime_finished?.toDate() || moment().toDate())
  }
  return (
    <>
      <ButtonGroup size="small">
        <Button onClick={handleFinish}>Finish</Button>
        <Button size="small" onClick={handleOpen} aria-label="finished at an earlier time">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Dialog open={!!open} onClose={handleCancel} fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Actual finish time</DialogTitle>
          <DialogContent>
            <DateTimePicker
              label="Actual finish time"
              value={datetime_finished}
              onChange={setDatetimeFinished}
              slotProps={{ textField: { fullWidth: true, variant: 'standard', margin: 'dense' } }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="submit">Finish</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default function Protocol({open, row, onClose, setData, onCommit, showMessage, ...props}) {
  const rows = useMemo(() => row && (row?.length ? row : [row]), [row])
  const [error, setError] = useState()
  const [row_ids, setRowIds] = useState()
  useEffect(() => {
    const newIds = rows?.map?.(x => x.id)
    if (newIds?.length !== row_ids?.length || newIds?.some(id => !row_ids?.includes(id)))
      setRowIds(rows?.map?.(x => x.id))
  }, [rows]) // eslint-disable-line
  // for row_ids dep
  const [protocol, setProtocol] = useState(undefined)
  const [isFullScreen, setFullScreen] = useState(false)
  useEffect(() => {
    setError(undefined)
    if (!row_ids)
      setProtocol(undefined)
    else
      axios.get(`/api/wetlab/protocols/generic/protocol?id=${row_ids.join(",")}`)
        .then(({data}) => {
          if (data.error)
            setError({message: data.error})
          else
            setProtocol(data)
        })
        .catch(err => setError(err))
  }, [row_ids])
  const [_activeStepIndex, setActiveStepIndex] = useState() // some steps may have the same step position
  const _activeStep = useMemo(() => rows?.[0]?.active_step, [rows])
  const activeStepIndex = _activeStepIndex || (_activeStep && protocol?.findIndex(step => step.position === _activeStep)) || 0
  const handleChangeStepIndex = (newStepIndex) => {
    if (protocol[newStepIndex].position !== _activeStep) {
      const newStep = protocol[newStepIndex].position
      axios.post("/api/wetlab/protocols/generic/step", {id: row_ids, active_step: newStep})
      setData?.(prev => prev.map(x => row_ids.includes(x.id) ? {...x, active_step: newStep} : x))
    }
    setActiveStepIndex(newStepIndex)
  }
  const handleClose = () => {
    onClose?.()
  }
  const handleFinish = (datetime_finished) => {
    const datetime_finished_str = moment(datetime_finished).toISOString()
    axios.post("/api/wetlab/protocols/generic/finish", {id: row_ids, time_stop: datetime_finished_str})
    setData?.(prev => prev.map(x => row_ids.includes(x.id) ? {...x, time_stop: datetime_finished_str} : x))
    row_ids.forEach(id => onCommit?.(id))
    handleClose()
  }
  const handleGroupProtocols = () => {
    axios.post("/api/wetlab/protocols/generic/group", {ids: row_ids}).then(({data}) => {
      showMessage?.(`Successfully created group`)
    })
  }
  const isPrintMedia = useMediaQuery('print')
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile || isFullScreen || isPrintMedia}
      {...props}
    >
      <DialogTitle>{rows && (rows.length === 1 ? rows[0]?.title || "Protocol" : "Multiple protocols")}</DialogTitle>
        <Container className='hideOnPrint' disableGutters maxWidth={false}
          sx={(theme) => ({
            position: 'absolute',
            top: 8,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
          })}
        >
          <Tooltip title="Group protocols and add to dashboard">
            <IconButton sx={(theme) => ({color: theme.palette.grey[500],})}
              onClick={handleGroupProtocols}
              aria-label="group protocols"
            >
              <JoinFullIcon />
            </IconButton>
          </Tooltip>
          {!isMobile && 
            <IconButton sx={(theme) => ({color: theme.palette.grey[500],})}
              aria-label="toggle full screen"
              onClick={() => setFullScreen(!isFullScreen)}
              >
                {isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
            </IconButton>
          }
        </Container>
      <DialogContent>
        {protocol === undefined ? (
          error ?
            <DialogContentText>{error.response?.data?.error || error.message}</DialogContentText>
            :
            <CircularProgress role="progressbar" />
          ) : 
          <Stepper
            role="tablist"
            orientation="vertical"
            aria-orientation="vertical"
            activeStep={isPrintMedia ? null : activeStepIndex}
          >
            {protocol?.map((step, index) => (
              <Step key={index} expanded={!!isPrintMedia || undefined}>
                <StepLabel
                  role="tab"
                  id={`step-${index + 1}`}
                  aria-label={step.title || step.type}
                  aria-selected={activeStepIndex === index}
                  optional={step.type === "thermocycle" && calculate_time_togo(step)}
                  onClick={() => handleChangeStepIndex(index)}
                  sx={[
                    {cursor: "pointer"},
                    {'.Mui-disabled': {cursor: "pointer"}}
                  ]}
                >
                  {step.title || step.type} {step.group && <Typography variant="caption">({step.group})</Typography>}
                </StepLabel>
                <StepContent
                  role="tabpanel"
                  aria-labelledby={`step-${index + 1}`}
                >
                  {
                    (step.type === "retrieve" && <RetrieveStep rows={step.data} />) ||
                    (step.type === "mix" && <MixStep product={step.data.product} ingredients={step.data.recipe} />) ||
                    (step.type === "agarosegelpurify" && <AgaroseGelStep data={step.data} />) ||
                    (step.type === "thermocycle" &&
                      <ThermocyclingStep
                        row_ids={row_ids}
                        setData={setData}
                        data={step.data}
                        />
                    ) ||
                    (step.type === "tablestep" && (
                      <TableContainer>
                        <Table size="small">
                          {step.head && <TableHead>
                            <TableRow>
                              {step.head.map(col => <TableCell>{col}</TableCell>)}
                            </TableRow>
                          </TableHead>}
                          <TableBody>
                            {step.rows?.map(row => (
                              <TableRow>
                                {row.map(cell => <TableCell>{cell}</TableCell>)}
                              </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )) ||
                    (step.type === "plaintextstep" && <Typography variant="body2">{step.data}</Typography>) ||
                    (step.type === "nanodropstep" && <NanodropStep samples={step.data} />)
                  }
                </StepContent>
              </Step>
            ))}
          </Stepper>
        }
      </DialogContent>
      <DialogActions className='hideOnPrint'>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={() => handleChangeStepIndex(activeStepIndex - 1)} disabled={error || (activeStepIndex === 0)}>Back</Button>
        {activeStepIndex >= protocol?.length - 1 ? (
          <FinishButton onClick={handleFinish} />
          ) : (
            <Button onClick={() => handleChangeStepIndex(activeStepIndex + 1)} disabled={error}>Next</Button>
          )}
        
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Paper, Select, Step, StepContent, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import WarningIcon from '@mui/icons-material/Warning';

export default function Inventory() {
  const [activeStep, setActiveStep] = useState(0)
  let [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState(null)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [preview, setPreview] = useState(null)
  const [updates, setUpdates] = useState(null)

  const handleLogin = () => {
    axios.get('/api/public/inventory/login').then(({data}) => {
      if (data.authenticated)
        console.log('Logged in!')
      else
        window.location.href = data.authorization_url
    })
  }

  useEffect(() => {
    if (searchParams.size > 0) {
      axios.post('/api/public/inventory/token', Object.fromEntries(searchParams.entries())).then(({data}) => {
        if (data.success)
          setToken(data.token)
        else
          alert('Failed to get token')
      })
      setSearchParams()
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (token && activeStep === 0)
      setActiveStep(1)
  }, [token, activeStep])

  const handleSelectCategory = (categories) => {
    setSelectedCategories(categories)
    setActiveStep(2)
  }

  const handleProceedToReview = (items) => {
    axios.get('/api/public/inventory/get_preview', {
      params: {
        items: Object.keys(items),
        quantities: Object.values(items).map(([qty]) => qty),
        urgency: Object.values(items).map(([, urgency]) => urgency)
      },
      paramsSerializer: {indexes: null},
      headers: {
        'X-GoogleOAuth-Token': token
      }
    }).then(({data : {table}}) => {
      setPreview(table)
    })
    setActiveStep(3)
  }

  const handleBack = () => {
    setActiveStep(prev => prev - 1)
  }

  const handleSubmit = () => {
    axios.post('/api/public/inventory/submit', preview, {
      headers: {
        'X-GoogleOAuth-Token': token
      }
    }).then(({data : {success, updates}}) => {
      if (!success) {
        alert('Failed to submit')
        return
      }
      setUpdates(updates)
      setActiveStep(4)
    })
  }

  const colItemQuery = preview?.columns?.findIndex(header => header === "item")
  const colItemDiff = preview?.columns?.findIndex(header => header === "Item name")
  const colWarning = preview?.columns?.findIndex(header => header === "warning")

  return (
    <Box padding={2} sx={{width: '100%'}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Login</StepLabel>
            <StepContent>
              <Button onClick={handleLogin}>Login with Google</Button>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Select inventory category</StepLabel>
            <StepContent>
              <WhoAmI token={token} onSubmit={handleSelectCategory} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Order supplies</StepLabel>
            <StepContent>
              <InventoryList token={token} selectedCategories={selectedCategories} onSubmit={handleProceedToReview} onBack={handleBack} />
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Review and submit</StepLabel>
            <StepContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {preview?.columns?.map((item, i) => i === colItemQuery || i === colWarning ? null : <TableCell key={i}>{item}</TableCell>)}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {preview?.data?.map((row, i) => (
                      <TableRow key={i}>
                        {row.map((item, j) => {
                          if (j === colItemDiff)
                            return <TableCell key={j}>
                              <Typography sx={{display: 'inline'}}>{item.map(([op, val], i) => {
                                  if (op === '+')
                                    return <span key={i} style={{ backgroundColor: 'green' }}>{val}</span>
                                  if (op === '-')
                                    return <span key={i} style={{ textDecoration: 'line-through', backgroundColor: 'red' }}>{val}</span>
                                  return <span key={i}>{val}</span>
                                })}
                              </Typography>
                              {row?.[colWarning] && 
                                <Tooltip
                                  title={row[colWarning]}
                                  sx={{display: 'inline', ml: 1, verticalAlign: 'middle'}}
                                >
                                  <WarningIcon color="warning" fontSize="small" />
                                </Tooltip>
                              }
                            </TableCell>
                          else if (j === colItemQuery || j === colWarning)
                            return null
                          return <TableCell key={j}>{item}</TableCell>
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </Box>
            </StepContent>
          </Step>
          <Step>
            <StepLabel>Done</StepLabel>
            <StepContent>
              Successfully updated {updates?.updatedRows} row{updates?.updatedRows === 1 ? '' : 's'} with {updates?.updatedColumns} column{updates?.updatedColumns === 1 ? '' : 's'} in the range {updates?.updatedRange}.

              <Box sx={{ mb: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => window.open(`https://docs.google.com/spreadsheets/d/${updates?.spreadsheetId}/edit?range=${updates?.updatedRange?.split("!")?.[1]}`, "_blank")}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Go to Spreadsheet
                </Button>
              </Box>
            </StepContent>
          </Step>
      </Stepper>
      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  )
}

function WhoAmI({token, onSubmit}) {
  const [data, setData] = useState(undefined)
  const [selectedCategories, setSelectedCategories] = useState([])
  useEffect(() => {
    axios.get('/api/public/inventory/whoami', {
      headers: {
        'X-GoogleOAuth-Token': token
      }
    }).then(({data}) => {
      setData(data)
      if (data.suggested_categories)
        setSelectedCategories(data.suggested_categories)
    })
  }, [token])
  const handleCheck = (e) => {
    if (e.target.checked)
      setSelectedCategories([...selectedCategories, e.target.name])
    else
      setSelectedCategories(selectedCategories.filter(category => category !== e.target.name))
  }
  const handleSubmit = () => {
    onSubmit?.(selectedCategories)
  }
  return (
    <Box>
      <Typography>Hi {data?.user}, which supplies do you order?</Typography>
      <FormGroup>
        {data?.categories.map((category, i) => (
          <FormControlLabel key={i} control={<Checkbox name={category} />} label={category} checked={selectedCategories.includes(category)} onChange={handleCheck} />
        ))}
      </FormGroup>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 1, mr: 1 }}
          disabled={data === undefined}
        >
          Next
        </Button>
        {data?.hint &&
          <Tooltip title={data?.hint} sx={{ml: 1}}>
            <IconButton>
              <TipsAndUpdatesIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
    </Box>
  )
}

function InventoryList({token, selectedCategories, onSubmit, onBack}) {
  const [data, setData] = useState(undefined)
  const [checkedIds, setCheckedIds] = useState([])
  const [qty, setQty] = useState({})
  const [urgency, setUrgency] = useState({})
  useEffect(() => {
    axios.get(`/api/public/inventory/get_list`, {
      params: {categories: selectedCategories},
      paramsSerializer: {indexes: null},
      headers: {
        'X-GoogleOAuth-Token': token
      }
    }).then(({data}) => {
      setData(data)
    })
  }, [token, selectedCategories])
  const handleCheck = (i) => {
    if (checkedIds.includes(i))
      setCheckedIds(checkedIds.filter(id => id !== i))
    else
      setCheckedIds([...checkedIds, i])
  }
  const handleAddToCart = (i) => {
    setQty({...qty, [i]: 1})
    setUrgency({...urgency, [i]: "Medium"})
  }
  const handleChangeQty = (i, e) => {
    setQty({...qty, [i]: e.target.value})
  }
  const handleChangeUrgency = (i, e) => {
    setUrgency({...urgency, [i]: e.target.value})
  }
  const handleBlur = (i) => {
    if (qty[i] === "")
      setQty({...qty, [i]: 0})
  }
  const handleSubmit = () => {
    onSubmit?.(
      Object.fromEntries(Object.entries(qty).filter(([_, value]) => value > 0).map(([key, value]) => [data.items[key][0], [value, urgency[key]]]))
    )
  }
  const handleBack = () => {
    onBack?.()
  }
  return (
    <Box>
      <List>
        {data?.items.map((item, i) => (
          <ListItem disablePadding dense onClick={() => handleCheck(i)} sx={{pr: (qty[i] > 0) || (qty[i] === "") ? "170px" : null}} >
            <ListItemButton>
              <ListItemIcon sx={{minWidth: 0, pr: 1}}>
                <Checkbox edge="start" tabIndex={-1} disableRipple onChange={() => handleCheck(i)} checked={checkedIds.includes(i)} />
              </ListItemIcon>
              <ListItemText primary={item[0]} />
            </ListItemButton>
            <ListItemSecondaryAction>
              {(qty[i] > 0) || (qty[i] === "") ?
                <>
                  <TextField
                    label="Qty"
                    sx={{minWidth: "60px", maxWidth: "60px", mr: 0.5}}
                    size="small"
                    value={qty[i]}
                    onChange={e => handleChangeQty(i, e)}
                    onBlur={() => handleBlur(i)}
                    inputProps={{inputmode: "numeric", pattern: "[0-9]*"}}
                  />
                  <FormControl
                    sx={{minWidth: "80px", maxWidth: "80px"}}
                    size="small"
                  >
                    <InputLabel id={`urgency${i}-label`}>Urgency</InputLabel>
                    <Select labelId={`urgency${i}-label`} id={`urgency${i}`} label="Urgency" value={urgency[i]} onChange={e => handleChangeUrgency(i, e)}>
                      <MenuItem value="Low">Low</MenuItem>
                      <MenuItem value="Medium">Med</MenuItem>
                      <MenuItem value="High">High</MenuItem>
                    </Select>
                  </FormControl>
                </>
                :
                <IconButton edge="end" onClick={() => handleAddToCart(i)}>
                  <AddShoppingCartIcon fontSize='small' />
                </IconButton>}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
          onClick={handleSubmit}
          disabled={(data === undefined) || Object.values(qty).filter(qty => qty > 0).length === 0}
        >
          Next
        </Button>
        <Button
          onClick={handleBack}
          sx={{ mt: 1, mr: 1 }}
        >
          Back
        </Button>
      </Box>
    </Box>
  )
}

import React, { useEffect, useRef } from 'react';
import {
  TextField,
  Grid,
} from '@mui/material';
import { onSpaceOrEnter } from '@mui/x-date-pickers/internals';

const onDelete = (innerFn) => event => {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    innerFn(event);

    // prevent any side effects
    event.preventDefault();
    event.stopPropagation();
  }
};

const sequentialFn = (fn1, fn2) => args => {
  fn1(args)
  fn2(args)
}

export default function LocationField({location_box, location_pos, onOpen, onClear, view}) {
  const refBox = useRef()
  const refPos = useRef()
  useEffect(() => {
    if (view === "box") {
      refBox.current.focus()
      refPos.current.blur()
    }
    else if (view === "pos") {
      refBox.current.blur()
      refPos.current.focus()
    }
    else {
      refBox.current.blur()
      refPos.current.blur()
    }
  }, [view])
  return (
		<Grid container spacing={1}>
      <Grid item xs={8}>
			<TextField
        inputRef={refBox}
        fullWidth
        variant="standard"
        label="Box"
        margin="dense"
				value={location_box?.name || ""}
        onClick={() => onOpen("box")}
        onKeyDown={sequentialFn(
          onSpaceOrEnter(() => onOpen("box")),
          onDelete(() => onClear("box"))
          )}
        InputProps={{
          readOnly: true,
        }}
			/>
      </Grid><Grid item xs={4}>
      <TextField
        inputRef={refPos}
        fullWidth
        variant="standard"
        label="Position"
        margin="dense"
        value={location_pos || ""}
        onClick={() => onOpen("position")}
        onKeyDown={sequentialFn(
          onSpaceOrEnter(() => onOpen("position")),
          onDelete(() => onClear("position"))
          )}
        InputProps={{
          readOnly: true,
        }}
      />
      </Grid>
		</Grid>
		)
}

import React from 'react';
import {
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import moment from 'moment';

const DAY_SIZE = 36 // from @mui/x-date-pickers/internals/constants/dimensions
const DAY_MARGIN = 2

// styling taken from MUI DayCalendar https://github.com/mui/mui-x/blob/97220aaf2964a9dcd08d6a2a263f4f1610d71a75/packages/x-date-pickers/src/DateCalendar/DayCalendar.tsx

const PickersBoxHeader = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const PickersBoxColumnLabel = styled(Typography)(({ theme }) => ({
  width: 36,
  height: 40,
  margin: '0 2px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.disabled,
}));

const PickersBoxRowContainer = styled('div')({ overflow: 'hidden' });

const PickersBoxRow = styled('div')({
  margin: `${DAY_MARGIN}px 0`,
  display: 'flex',
  justifyContent: 'center',
});

const PickersBoxRowNumber = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
  width: DAY_SIZE,
  height: DAY_SIZE,
  padding: 0,
  margin: `0 ${DAY_MARGIN}px`,
  color: theme.palette.text.disabled,
  fontSize: '0.75rem',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'inline-flex',
}));

export default function LocationPositionViewer({numRows, numCols, occupiedPositions, selectedPositions, rowsLettered, onSelect}) {
  const unravelIdx = (row, col) => numCols ? row * numCols + col : col
  if (occupiedPositions === undefined)
    return <CircularProgress />
  return (
    <div role="grid">
      <PickersBoxHeader role="row">
        <PickersBoxColumnLabel /> {/* gap for row labels */}
          {[...Array(numCols).keys()].map(col => (
            <PickersBoxColumnLabel
              key={col}
              variant="caption"
              role="columnheader"
            >
              {col + 1}
            </PickersBoxColumnLabel>
          ))}
      </PickersBoxHeader>
      <PickersBoxRowContainer role="rowgroup">
        {[...Array(numRows).keys()].map((row, index) => (
          <PickersBoxRow
            key={row}
            role="row"
            aria-rowindex={index + 1}
          >
            <PickersBoxRowNumber
              role="rowheader"
              scope="row"
            >
              {rowsLettered ? "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[row] : row + 1}
            </PickersBoxRowNumber>
            {[...Array(numCols).keys()].map(col => {
              const selected = selectedPositions?.includes(unravelIdx(row, col) + 1)
              return (
                <PickersDay
                  key={col}
                  role="gridcell"
                  day={moment()}
                  aria-selected={selected}
                  selected={selected}
                  disabled={occupiedPositions[unravelIdx(row, col)] || false} // TODO: should enable if selected
                  // today={!container.position_occupied[unravelIdx(row, col)]}
                  isFirstVisibleCell={false}
                  isLastVisibleCell={false}
                  outsideCurrentMonth={false}
                  onDaySelect={() => onSelect?.(selected ? null : unravelIdx(row, col) + 1)}
                >
                  {unravelIdx(row, col) + 1}
                </PickersDay>
              )
            })}
          </PickersBoxRow>
        ))}
      </PickersBoxRowContainer>
    </div>
  )
}

import React from 'react'
import SampleDataGrid from 'layouts/SampleDataGrid'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Protocol from 'protocols/Protocol';
import { sampleNameComparator } from 'utils/utils';
import { Chip, Tooltip, styled, List } from '@mui/material';
import moment from 'moment';

const ListItem = styled('li')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  listStyle: 'none',
  cursor: 'pointer',
}))

const oligo_to_listitem = f =>
  <ListItem key={f.id}>
    <Tooltip title={
      <>
        <div>40 pmol</div>
        <div>{f.container?.name && `${f.container?.name}, #${f.location_pos}`}</div>
        <div>{f.date && moment(f.date).format("M/D/YYYY")}</div>
        <div>{f.concentration && `${(f.concentration / 1000).toLocaleString("en-US", {maximumFractionDigits: 1})}μM`}</div>
        <div>{f.description}</div>
      </>
    }>
      <Chip
        key={f.id}
        size="small"
        variant="outlined"
        label={f.name}
      />
    </Tooltip>
  </ListItem>

export default function Anneal() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/protocols/anneal/create",
        read: "/api/wetlab/protocols/anneal/read",
        update: "/api/wetlab/protocols/anneal/update",
        delete: "/api/wetlab/protocols/anneal/discard"}}
      actions={[
        {
          label: "Step by Step",
          icon: <FormatListNumberedIcon />,
          multiple: true,
          Form: Protocol,
        }
      ]}
      columns={[
        {
          field: 'completed',
          headerName: 'Done',
          width: 40,
          editable: false,
          type: "boolean",
          valueGetter: (_, row) => !!row.time_stop
        },
        {
          field: 'product',
          headerName: 'Product',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.product?.name,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'oligos',
          headerName: 'Oligos',
          width: 200,
          editable: false,
          renderCell: params => 
            <List sx={{p: 0, display: "flex", flexDirection: "row"}}>
              {params.row.oligo1 && oligo_to_listitem(params.row.oligo1)}
              {params.row.oligo2 && oligo_to_listitem(params.row.oligo2)}
            </List>
        },
        {
          field: 'sequence',
          headerName: 'Sequence',
          width: 200,
          editable: false,
          valueGetter: (_, row) => row.product?.sequence,
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 1,
          editable: false,
          valueGetter: (_, row) => row.product?.description,
        },
        {
          field: 'volume',
          headerName: 'μL',
          width: 60,
          editable: false,
          valueGetter: (_, row) => row.volume,
        },
      ]}
      initialState={{
        sorting: {
          sortModel: [
            { field: "completed", sort: "asc" },
            { field: "product", sort: "asc" },
          ],
        },
      }}
    />
  )
}

import React from 'react';
import {
  styled,
  IconButton,
  Skeleton,
} from '@mui/material';
import { pickersCalendarHeaderClasses } from '@mui/x-date-pickers';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PickersLocationHeaderRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
  marginBottom: 8,
  paddingLeft: 24,
  paddingRight: 12,
  // prevent jumping in safari
  maxHeight: 30,
  minHeight: 30,
});

const PickersLocationHeaderLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: 'auto',
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightMedium,
}));

const PickersLocationHeaderLabel = styled('div')({
  marginRight: 6,
});

const PickersLocationHeaderSwitchViewButton = styled(IconButton)(({ view }) => ({
  marginRight: 'auto',
  ...(view === 'box' && {
    [`.${pickersCalendarHeaderClasses.switchViewIcon}`]: {
      transform: 'rotate(180deg)',
    },
  }),
}));

const PickersLocationHeaderSwitchViewIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  willChange: 'transform',
  transition: theme.transitions.create('transform'),
  transform: 'rotate(0deg)',
}));

export default function LocationPickerHeader({name, view, onChangeView, ...props}) {
  return (
		<PickersLocationHeaderRoot>
			<PickersLocationHeaderLabelContainer>
				<PickersLocationHeaderLabel onClick={() => onChangeView(view === "box" ? "position" : "box")}>
					{name || <Skeleton width={76} />}
				</PickersLocationHeaderLabel>
				<PickersLocationHeaderSwitchViewButton
					view={view}
          aria-label={view === "box" ? "box view is open, switch to position view" : "position view is open, switch to box view"}
					onClick={() => onChangeView(view === "box" ? "position" : "box")}
				>
					<PickersLocationHeaderSwitchViewIcon className={pickersCalendarHeaderClasses.switchViewIcon} />
				</PickersLocationHeaderSwitchViewButton>
			</PickersLocationHeaderLabelContainer>
		</PickersLocationHeaderRoot>
		)
}

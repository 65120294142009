import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  DialogContentText,
} from '@mui/material';
import axios from 'axios';
import { formatNumberPipettableVolume } from 'utils/utils';

export default function PassageCellsForm({open, row, onClose, setData, onCommit, ...props}) {
  const [volume, setVolume] = useState("") // set default volume to current culture volume
  useEffect(() => {
    row?.volume && setVolume(prev => prev ? prev : row.volume)
  }, [row?.volume])
  const [cells, setCells] = useState("") // set default cells to 10x dilution
  useEffect(() => {
    row?.cells && setCells(prev => prev ? prev : Math.round(row.cells / 10))
  }, [row?.cells])
  const [passage_increment, setPassageIncrement] = useState(1)
  const passage = row?.passage && row?.passage + passage_increment
  const [date, setDate] = useState(moment())
  const [density, setDensity] = useState(null)
  const display_cells = cells || Math.round(density * volume / 1000)
  const display_density = density || Math.round(1000 * cells / volume)
  const display_dilution = (row.cells / display_cells).toLocaleString("en-US", {maximumSignificantDigits: 2, useGrouping: false})
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const id = row.id
    const volumeDiscarded = row.volume - display_volumeToKeep
    const volumeAdded = volume - display_volumeToKeep
    const finalVolume = volume
    const prev_density = row.cells / row.volume
    axios.post("/api/wetlab/tc_basic/passage", {id: id, volumeDiscarded, volumeAdded, date: date.toISOString(), passage_increment})
    setData?.(prev => prev.map(x => x.id === id ? (
      {...x,
        passage: x.passage && x.passage + passage_increment,
        date_seed: date,
        volume: finalVolume,
        cells: x.cells - (volumeDiscarded * prev_density),
      }) : x))
    onCommit?.(id, volumeDiscarded, volumeAdded, finalVolume)
    handleClose()
  }
  const display_volumeToKeep = display_cells * row.volume / row.cells
  const volumeAdded = volume - display_volumeToKeep
  const validateCells = display_cells > row.cells
  if (!row)
    return null
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      {row && (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Passage {row.name} Cells</DialogTitle>
          <DialogContent>
              <TextField
                fullWidth
                margin="dense"
                label="Cells"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={display_cells || ""}
                error={!!cells && validateCells}
                helperText={!!cells && validateCells && "Not enough cells"}
                onChange={e => {setCells(e.target.value); setDensity(null)}}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Volume"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={volume || ""}
                onChange={e => setVolume(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">μL</InputAdornment>,
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Density"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={display_density || ""}
                error={!!density && validateCells}
                helperText={!!density && validateCells && "Not enough cells"}
                onChange={e => {setDensity(e.target.value); setCells(null)}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">cells / mL</InputAdornment>,
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Dilution"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={display_dilution || ""}
                onChange={e => setCells(Math.floor(row.cells / e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">×</InputAdornment>,
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Passage"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={(passage_increment >= 0 ? `+${passage_increment}` : passage_increment) || ""}
                onChange={e => setPassageIncrement(parseInt(e.target.value) || 0)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">to p{passage}</InputAdornment>,
                }}
              />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                slotProps={{
                  textField: {
                    margin: "dense",
                    fullWidth: true,
                    variant: "standard"
                  }
                }}
                format="M/D/YY h:mm A"
                label="Date"
                value={date}
                onChange={setDate}
              />
            </LocalizationProvider>
            <DialogContentText>
              <ol>
                <li>Keep {formatNumberPipettableVolume(display_volumeToKeep)} culture (equivalently discard {formatNumberPipettableVolume(row.volume - display_volumeToKeep)}).</li>
                {volumeAdded >= 0 ? (
                  <li>Add {formatNumberPipettableVolume(volumeAdded)} fresh media to final volume {formatNumberPipettableVolume(volume)}.</li>
                ) : (
                  <>
                    <li>Spin down and aspirate supernatant.</li>
                    <li>Resuspend in final volume {formatNumberPipettableVolume(volume)}.</li>
                  </>
                )}
              </ol>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Done</Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

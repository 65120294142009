import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog, DialogContent, DialogActions, DialogTitle,
  TextField, InputAdornment, Grid, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel,
  Box, Paper,
  useMediaQuery,
} from '@mui/material';
import SampleDataGrid from '../layouts/SampleDataGrid';
import LocationPositionViewer from 'components/SampleLocationPicker/LocationPositionViewer';
import axios from 'axios';

function AddForm({open, onClose, onSubmit, ...props}) {
  const default_new_container = {position_unraveled: false, position_rows_lettered: false}
  const [container, setContainer] = useState(default_new_container);
  const handleClose = () => {
    onClose();
    setContainer(default_new_container);
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(container)
    handleClose();
  }
  const handleChange = (changes) => {
    setContainer(prev => ({...prev, ...changes}))
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Container</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={container && container.name}
            onChange={e => handleChange({name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={container && container.description}
            onChange={e => handleChange({description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Temperature"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={container && container.temperature}
            onChange={e => handleChange({temperature: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label="Location"
            fullWidth
            variant="standard"
            value={container && container.location}
            onChange={e => handleChange({location: e.target.value})}
          />
          <Grid container spacing={1} sx={{mb: 2}}>
            <Grid item>
              <TextField
                margin="dense"
                label="Rows"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={container && container.num_rows}
                onChange={e => handleChange({num_rows: e.target.value})}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                label="Columns"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={container && container.num_cols}
                onChange={e => handleChange({num_cols: e.target.value})}
              />
            </Grid>
          </Grid>
          <FormControl>
            <FormLabel>Position Labeling</FormLabel>
            <RadioGroup
              name="position-labeling-group"
              row
              value={2 * container.position_unraveled + container.position_rows_lettered}
              onChange={e => handleChange({position_unraveled: Boolean(e.target.value / 2), position_rows_lettered: Boolean(e.target.value % 2)})}
            >
              <FormControlLabel value={0} control={<Radio />} label="1" />
              <FormControlLabel value={2} control={<Radio />} label="1,1" disabled={!container || !container.num_rows || container.num_rows <= 1} />
              <FormControlLabel value={3} control={<Radio />} label="A1" disabled={!container || !container.num_rows || container.num_rows <= 1} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function DetailPanelContent({id}) {
  const [availablePositions, setAvailablePositions] = useState()

  useEffect(() => {
    let isMounted = true;
    (async () => {
      const {data : json} = await axios.get(`/api/wetlab/container/getAvailablePositions?id=${id}`)
      if (!isMounted)
        return
      setAvailablePositions(json)
    })()
    return () => {
      isMounted = false
    }
  }, [id])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper sx={{ p: 2, display: 'inline-block', m: 2 }}>
        {availablePositions &&
          <LocationPositionViewer
            numRows={availablePositions?.num_rows}
            numCols={availablePositions?.num_cols}
            rowsLettered={availablePositions?.position_rows_lettered && availablePositions?.position_unraveled}
            occupiedPositions={availablePositions?.position_occupied}
          />
        }
      </Paper>
    </Box>
  );
}

export default function Containers() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const columns_hidden_mobile = isMobile && {description: false}
  const getDetailPanelContent = useCallback(({id}) => <DetailPanelContent id={id} />, [])
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/container/create",
        read: "/api/wetlab/container/read",
        update: "/api/wetlab/container/update",
        delete: "/api/wetlab/container/delete"}}
      AddForm={AddForm}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          width: 180,
          editable: true,
        },
        {
          field: 'temperature',
          type: 'number',
          headerName: "Temp",
          editable: true,
          width: 80,
          valueFormatter: value => value && `${value}°C`
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 240,
          flex: 1,
          editable: true,
        },
        {
          field: 'location',
          headerName: 'Location',
          width: 180,
          editable: true,
          hidden: true,
        },
        {
          field: 'position_unraveled',
          headerName: "Unraveled",
          type: 'boolean',
          editable: true,
          width: 80,
        },
        {
          field: 'position_rows_lettered',
          headerName: "Lettered",
          type: 'boolean',
          editable: true,
          width: 80,
        },
        {
          field: 'num_rows',
          headerName: "Rows",
          type: 'number',
          editable: true,
          width: 80,
        },
        {
          field: 'num_cols',
          headerName: "Columns",
          type: 'number',
          editable: true,
          width: 80,
        },
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
        columns: {
          columnVisibilityModel: { // not visible, other cols remain visible
            location: false,
            position_unraveled: false,
            position_rows_lettered: false,
            num_rows: false,
            num_cols: false,
            ...columns_hidden_mobile
          },
        },
      }}
      checkboxSelection={false}
      disableRowSelectionOnClick={false}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
    />
  )
}

import React from 'react'
import { Tooltip } from '@mui/material'
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorIcon from '@mui/icons-material/Error';

export default function DNAVerified({name, sequence_verified, sequencing_notes}) {
  if (sequence_verified === true)
    return <>{name}<Tooltip title={sequencing_notes || "Sequence verified"}><VerifiedIcon sx={{fontSize: 14, marginLeft: 1, verticalAlign: 'middle'}} /></Tooltip></>
  else if (sequence_verified === false)
    return <>{name}<Tooltip title={sequencing_notes || "Failed sequence verification"}><ErrorIcon color="error" sx={{fontSize: 18, marginLeft: 1, verticalAlign: 'middle'}} /></Tooltip></>
  else
    return <>{name}</>
}

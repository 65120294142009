import React, { useEffect, useState } from 'react';
import LocationPickerDialog from 'components/SampleLocationPicker/LocationPickerDialog';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';

export default function PutAwayForm({open, row : rows, onClose, setData, onCommit, showMessage, api_update, ...props}) {
  const [location_box, setLocationBox] = useState()
  const [location_pos, setLocationPos] = useState()
  const [containers, setContainers] = useState(null)
  const container = containers?.find(x => x.id === location_box)
  const [pickerView, setPickerView] = useState(null)
  useEffect(() => {
    axios.get("/api/wetlab/container/read").then(({data : {data}}) => setContainers(data))
  }, [])
  // TODO: this data can't be that big. just fetch it all at once.
  useEffect(() => {
    !!location_box && !!container && !container.position_occupied && 
      axios.get(`/api/wetlab/container/getAvailablePositions?id=${location_box}`)
      .then(({data : container}) => setContainers(prev => prev.map(container_prev => container_prev.id === location_box ? {...container_prev, ...container} : container_prev)))
  }, [location_box, container]);
  useEffect(() => {
    setPickerView(open && (location_box ? "position" : "box"))
  }, [open, location_box])
  useEffect(() => {
    setLocationBox(prev => prev || rows?.find?.(row => row.location_box)?.location_box)
    setLocationPos(prev => rows?.map?.((row, i) => prev?.[i] || (row.location_box === location_box ? row.location_pos : null)))
  }, [rows, location_box])
  const handleSubmit = () => {
    api_update && axios.post(api_update, rows.map((row, i) => ({id: row.id, location_box, location_pos: location_pos?.[i] || location_pos})))
      .then(({status}) => status === 200 && showMessage?.("Changes saved"))
    setData?.(prev => prev.map(row => {
      if (!rows.includes(row))
        return row
      const i = rows.indexOf(row)
      return {...row, location_box: location_box, location_pos: location_pos?.[i] || location_pos}
    }))
  }
  const handleChangeView = (newView) => {
    if (newView === null)
      onClose?.()
    setPickerView(newView)
  }
  return (
    <LocationPickerDialog
      options={containers}
      location_box={container}
      location_pos={location_pos}
      view={pickerView}
      onChangeView={handleChangeView}
      onChange={({location_box, location_pos}) => {
        location_box !== undefined && setLocationBox(location_box)
        location_pos !== undefined && setLocationPos(location_pos)
      }}
      onSubmit={handleSubmit}
      samples={rows}
    />
  );
}

export const PUTAWAY_FORM_MENUACTION_DEF = (api_update) => ({
  label: "Put Away",
  icon: <HomeIcon />,
  Form: (props) => <PutAwayForm api_update={api_update} {...props} />,
  multiple: true,
})

import { Typography } from "@mui/material";

export default function AgaroseGelStep({data}) {
  return (
    <>
      <Typography variant="body2">Keep: {data.length_keep.toLocaleString("en-us")} bp ({data.product.name})</Typography>
      {data.length_discard && <Typography variant="body2">Discard: {data.length_discard.map(x => `${x.toLocaleString("en-us")} bp`).join(' | ')}</Typography>}
    {/* <TableContainer>
      <Table size="small">
        <caption>
          <Typography variant="caption" color="text.secondary">
            Reference: <Link href="https://www.qiagen.com/zh-us/knowledge-and-support/knowledge-hub/bench-guide/dna/analysing-dna/dna-analysis-using-analytical-gels" target="_blank" rel="noopener" color="text.secondary" underline="hover">Qiagen</Link>
          </Typography>
        </caption>
        <TableHead>
          <TableRow>
            <TableCell>Agarose concentration (% w/v)</TableCell>
            <TableCell colSpan={2}>DNA fragment range (bp)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            [0.3, 5, 60],
            [0.5, 1, 30],
            [0.7, 0.8, 12],
            [1.0, 0.5, 10],
            [1.2, 0.4, 7],
            [1.5, 0.2, 3],
            [2.0, 0.1, 2],
          ].map(([conc, kbp_low, kbp_high], index) => 
            <TableRow key={index}>
              <TableCell>{conc.toLocaleString("en-us", {minimumFractionDigits: 1, maximumFractionDigits: 1})}</TableCell>
              <TableCell align="right">{(kbp_low * 1000).toLocaleString("en-us")}</TableCell>
              <TableCell align="right">{(kbp_high * 1000).toLocaleString("en-us")}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer> */}
    </>
  )
}

import React from 'react';
import {
  Typography,
  MenuItem,
  MenuList,
  ListItemText,
  Skeleton,
} from '@mui/material';

export default function LocationBoxViewer({value, options, onSelect, ...props}) {
  return (
		<MenuList variant="selectedMenu" {...props}>
			{options ? options.map(x => (
				<MenuItem
					key={x.id}
					selected={x.id === value}
					aria-label={x.name}
					aria-selected={x.id === value}
					onClick={() => onSelect(x.id)}
				>
					<ListItemText>
						{x.name}
						{x.temperature !== null && (
							<Typography variant="body2" color="text.secondary" display="inline">
								{` ${x.temperature}°C`}
							</Typography>
						)}
					</ListItemText>
					{x.num_positions_unoccupied !== null && (
						<Typography variant="body2" color="text.secondary">
							{x.num_positions_unoccupied} spot{x.num_positions_unoccupied === 1 ? '' : 's'}
						</Typography>
					)}
				</MenuItem>
			)) : Array(8).fill().map((_, index) => <MenuItem role="progressbar" key={index}><ListItemText><Skeleton /></ListItemText></MenuItem>)}
		</MenuList>
		)
}

import React, { useEffect, useState } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';

export default function DigestForm({open, onClose, onSubmit, showMessage, ...props}) {
  const [data, setData] = useState({product: {name: "fSPS"}, incubation_temp: 37, incubation_time: 3600, enzymes: []})
  const [options_dna, setOptionsDna] = useState()
  const [options_plasmids, setOptionsPlasmids] = useState()
  const options_templates = [...(options_dna || []), ...(options_plasmids || [])]
  const [options_enzymes, setOptionsEnzymes] = useState()
  const error_enzymes = data?.enzymes?.length > 3
  const error_fragments = data?.fragment_sizes?.some(x => !parseInt(x))
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit?.(data)
    handleClose()
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  // Get template options
  useEffect(() => {
    axios.get("/api/wetlab/dna/get").then(({data : {data}}) => setOptionsDna(data))
  }, [])
  useEffect(() => {
    axios.get("/api/wetlab/plasmids/get").then(({data: {data}}) => setOptionsPlasmids(data))
  }, [])
  // Get enzyme options
  useEffect(() => {
    axios.get("/api/wetlab/protocols/digest/enzymes").then(({data : {enzymes}}) => setOptionsEnzymes(enzymes?.map?.(
      ({name, sequence}) => ({id: name, name, description: sequence})
    )))
  }, [])
  // Get recommended fragment sizes
  useEffect(() => {
    if (!data.template?.id || !data.enzymes || data.enzymes.length === 0)
      return
    axios.get(`/api/wetlab/protocols/digest/fragments?template_id=${data.template.id}&enzymes=${data.enzymes.join(",")}`).then(({data : {fragment_sizes, fragment_seqs}}) => {
      setData(prev => ({
        ...prev,
        product: {
          ...prev.product,
          length: fragment_sizes?.[0],
          sequence: fragment_seqs?.[0],
        },
        fragment_sizes
      }))
    })
  }, [data.template?.id, data.enzymes])
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Restriction Digest</DialogTitle>
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Product"
            fullWidth
            variant="standard"
            value={data?.product?.name || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, name: e.target.value}}))}
          />
          <DNASelector
            label="Template"
            options={options_templates}
            concentrationUnit="ng/μL"
            onChange={(e, v) => handleChange({template: v})}
            value={data?.template || null}
          />
          <DNASelector
            label="Enzymes"
            options={options_enzymes}
            onChange={(e, v) => handleChange({enzymes: v.map(e => e.id || e)})}
            value={data?.enzymes.map(id => options_enzymes.find(x => x.id === id) || id) || []}
            multiple
            freeSolo
            concentrationUnit="U/μL"
            error={error_enzymes}
            helperText={error_enzymes && "Maximum of 3 enzymes"}
          />
          <DNASelector
            label="Fragments"
            options={[]}
            onChange={(e, v) => {
              const newVal = parseInt(v?.[0]) || null
              setData(prev => ({...prev, fragment_sizes: v, product: {...prev.product, length: newVal}}))
            }}
            value={data?.fragment_sizes || []}
            freeSolo
            multiple
            error={error_fragments}
            helperText={error_fragments && "Must be integers"}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant={index === 0 ? "filled" : "outlined"} size="small" label={`${option} bp`} {...getTagProps({ index })} />
              ))
            }
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Description"
                fullWidth
                variant="standard"
                value={data?.product?.description || ""}
                onChange={e => setData(prev => ({...prev, product: {...prev.product, description: e.target.value}}))}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Time"
                fullWidth
                variant="standard"
                value={data?.incubation_time || ""}
                InputProps={{
                  endAdornment: <InputAdornment position="end"> sec</InputAdornment>,
                }}
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                onChange={e => handleChange({incubation_time: e.target.value})}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Temperature"
                fullWidth
                variant="standard"
                value={data?.incubation_temp || ""}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                }}
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                onChange={e => handleChange({incubation_temp: e.target.value})}
              />
            </Grid>
          </Grid>
          <TextField
            disabled
            margin="dense"
            label="Sequence"
            fullWidth
            variant="standard"
            value={data?.product?.sequence || ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" disabled={error_enzymes || error_fragments}>Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import React from 'react'
import SampleDataGrid from 'layouts/SampleDataGrid'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Protocol from 'protocols/Protocol';
import { sampleNameComparator } from 'utils/utils';
import { Chip, Tooltip, styled, List } from '@mui/material';
import moment from 'moment';

const ListItem = styled('li')(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  listStyle: 'none',
  cursor: 'pointer',
}))

export default function Assembly() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/protocols/assembly/create",
        read: "/api/wetlab/protocols/assembly/read",
        update: "/api/wetlab/protocols/assembly/update",
        delete: "/api/wetlab/protocols/assembly/discard"}}
      actions={[
        {
          label: "Step by Step",
          icon: <FormatListNumberedIcon />,
          multiple: true,
          Form: Protocol,
        }
      ]}
      columns={[
        {
          field: 'completed',
          headerName: 'Done',
          width: 40,
          editable: false,
          type: "boolean",
          valueGetter: (_, row) => !!row.time_stop
        },
        {
          field: 'product',
          headerName: 'Product',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.product?.name,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'fragments',
          headerName: 'Fragments',
          flex: 1,
          editable: false,
          renderCell: params => 
            <List sx={{p: 0, display: "flex", flexDirection: "row"}}>
              {params.row.fragments.map(f => 
                <ListItem key={f.id}>
                  <Tooltip title={
                    <>
                      <div>{f.pmol && `${f.pmol} pmol`}</div>
                      <div>{f.container?.name && `${f.container?.name}, #${f.location_pos}`}</div>
                      <div>{f.date && moment(f.date).format("M/D/YYYY")}</div>
                      <div>{f.concentration && `${(f.concentration / 1000).toLocaleString("en-US", {maximumFractionDigits: 1})}ng/μL`}</div>
                      <div>{f.description}</div>
                    </>
                  }>
                    <Chip
                      key={f.id}
                      size="small"
                      variant="outlined"
                      label={f.name}
                    />
                  </Tooltip>
                </ListItem>
              )}
            </List>
        },
        {
          field: 'method',
          headerName: 'Method',
          width: 120,
          editable: false,
        },
        {
          field: 'volume',
          headerName: 'μL',
          width: 60,
          editable: true,
        },
      ]}
      initialState={{
        sorting: {
          sortModel: [
            { field: "completed", sort: "asc" },
            { field: "product", sort: "asc" },
          ],
        },
      }}
    />
  )
}

import { TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

export default function SampleNameField({value, ...props}) {
  const [warning, setWarning] = useState()
  useEffect(() => {
    if (!value)
    {
      setWarning(null)
      return
    }
    const timeout = setTimeout(() => {
      axios.get(`/api/wetlab/generic/namecheck?q=${value}`).then(({data : {ok, recommend}}) => {
        if (ok)
          setWarning(null)
        else if (!recommend)
          setWarning("Sample name already used")
        else
          setWarning(`Sample name already used. Consider ${recommend}`)
      })
    }, 500) // only fetch after pause; same logic as for search
    return () => clearTimeout(timeout)
  }, [value])
  return (
    <TextField
      value={value}
      helperText={warning || ""}
      color={warning ? "warning" : "primary"}
      {...props}
    />
  )
}

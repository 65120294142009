import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';
import PlasmidChoiceAndAmount from 'components/PlasmidChoiceAndAmount';

export default function LentivirusProductionForm({open, onClose, onSubmit, showMessage, row, ...props}) {
  const [data, setData] = useState({
    name: "SPS",
  })
  const [options_plasmids, setOptionsPlasmids] = useState()
  const [options_cells, setOptionsCells] = useState()
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    axios.post("/api/wetlab/tc_basic/lentivirus/add_bytransfection", data)
      .then(({status}) => status === 200 && showMessage("Saved new lentivirus production task and sample"))
    onSubmit?.(data)
    handleClose()
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  // Load data from row (plasmid)
  useEffect(() => {
    if (!row)
      return
    setData(prev => ({
      ...prev,
      plasmid_transfer: row,
    }))
  }, [row])
  // Get plasmid options
  useEffect(() => {
    axios.get("/api/wetlab/plasmids/get").then(({data: {data}}) => setOptionsPlasmids(data))
  }, [])
  // Get enzyme options
  useEffect(() => {
    axios.get("/api/wetlab/tc_basic/get").then(({data : {data}}) => setOptionsCells(data))
  }, [])
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Lentivirus from Plasmid</DialogTitle>
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={data?.name || ""}
            onChange={e => handleChange({name: e.target.value})}
          />
          <PlasmidChoiceAndAmount amount={750} unit="ng">
            <DNASelector
              label="Transfer Plasmid"
              options={options_plasmids}
              concentrationUnit="ng/μL"
              onChange={(e, v) => handleChange({plasmid_transfer: v})}
              value={data?.plasmid_transfer || null}
            />
          </PlasmidChoiceAndAmount>
          <PlasmidChoiceAndAmount amount={1500} unit="ng">
            <DNASelector
              label="Packaging Plasmid (Gag/Pol)"
              options={options_plasmids}
              concentrationUnit="ng/μL"
              onChange={(e, v) => handleChange({plasmid_packaging: v})}
              value={data?.plasmid_packaging || null}
            />
          </PlasmidChoiceAndAmount>
          <PlasmidChoiceAndAmount amount={750} unit="ng">
            <DNASelector
              label="Envelope Plasmid (VSVG)"
              options={options_plasmids}
              concentrationUnit="ng/μL"
              onChange={(e, v) => handleChange({plasmid_envelope: v})}
              value={data?.plasmid_envelope || null}
            />
          </PlasmidChoiceAndAmount>
          <DNASelector
            label="Producer Cells"
            options={options_cells}
            onChange={(e, v) => handleChange({cells: v})}
            value={data?.cells || null}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={data?.description || ""}
            onChange={e => handleChange({description: e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

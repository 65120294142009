import React, { useState } from 'react';
import { Button, TextField, Box, Container, Avatar, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function Login() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [msg, setMsg] = useState(null)
  const navigate = useNavigate()
  const handleSubmit = e => {
    e.preventDefault()
    axios.post('/api/login', {username, password})
      .then(({data : {success, msg}}) => {
        if (success)
          navigate("/")
        else
          setMsg(msg)
      })
  }
  const valid = username && password
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            label="Username"
            autoFocus
            value={username}
            onChange={e => setUsername(e.target.value)}
            autoComplete="username"
          />
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <Typography color="error" variant="body1" children={msg} />
          <Tooltip title="Ready to empty your mind?" followCursor>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!valid}
            >
              Login
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Container>
  )
}

import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText,
  TextField, InputAdornment, ToggleButtonGroup, ToggleButton,
} from '@mui/material';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SampleDataGrid from '../../layouts/SampleDataGrid';
import { DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ColorizeIcon from '@mui/icons-material/Colorize';
import ScienceIcon from '@mui/icons-material/Science';
import CircleIcon from '@mui/icons-material/Circle';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { AddFormForm } from './Plasmids';
import { AddFormForm as AddGlycerolStockFormForm } from './GlycerolStock'
import NanodropField from 'components/NanodropField';
import { nullableDateComparator, sampleNameComparator } from 'utils/utils';
import axios from 'axios';
import { GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import PickColoniesForm from 'forms/PickColoniesForm';

function GlycerolStockForm({open, onClose, row, setData, showMessage, ...props}) {
  const defualt_new_plasmid = {volume: 1000, date: moment()}
  const [plasmid, setPlasmid] = useState(defualt_new_plasmid);
  useEffect(() => {
    if (!row)
      return
    setPlasmid(prev => ({name: row?.name, description: row?.description, colony_id: row?.colony_id, ...prev}))
  }, [row])
  const handleClose = () => {
    onClose();
    setPlasmid(defualt_new_plasmid);
  }
  const handleSubmit = (event) => {
    axios.post("/api/wetlab/bacteria/glycerolstock/add", {...plasmid, source_id: row.id, date: moment(plasmid?.date).toISOString()})
      .then(({status}) => status === 200 && showMessage(`Added glycerol stock ${plasmid.name}`))
    setData?.(prev => prev.map(x => x.id === row.id ? {...x, volume: x.volume - plasmid.volume / 2} : x))
    handleClose();
  }
  const handleChange = (changes) => {
    setPlasmid(prev => ({...prev, ...changes}))
  }
  if (!open || !row)
    return null
  return (
    <AddGlycerolStockFormForm
      open={open}
      title={`Glycerol Stock for ${row?.name}`}
      plasmid={plasmid}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

export function MiniprepForm({open, row, onClose, setData, onCommit, showMessage, ...props}) {
  const [plasmid, setPlasmid] = useState({volume: 50, date: moment()});
  const getPlasmidLabel = (row) => row.colony_id ? `${row.name}-${row.colony_id}` : row.name
  useEffect(() => {
    if (!row)
      return
    setPlasmid(prev => {
      let newVal = {...prev}
      if (!prev?.name)
        newVal = {...newVal, name: row.name}
      if (!prev?.description)
        newVal = {...newVal, description: row.description}
      if (!prev?.colony_id)
        newVal = {...newVal, colony_id: row.colony_id}
      return newVal
    })
  }, [open, row])
  const handleClose = () => {
    onClose?.()
    // Reset default form values or persist values to simplify batch processing
    setPlasmid(prev => ({...prev, location_pos: undefined, name: null, description: null, concentration: null, date: moment(), colony_id: null}))
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const miniprep_volume = 1500 // TODO: assume 1.5mL culture used for miniprep
    const committed_data = {
      name: plasmid.name,
      description: plasmid.description,
      colony_id: plasmid.colony_id,
      volume: plasmid.volume ? plasmid.volume * 1000 : null,
      concentration: plasmid.concentration ? plasmid.concentration * 1000 : null,
      date: plasmid.date?.toISOString(),
      source_id: row.id,
      miniprep_volume: miniprep_volume,
      location_box: plasmid.location_box,
      location_pos: plasmid.location_pos
    }
    axios.post("/api/wetlab/plasmids/add", committed_data)
      .then(({status}) => status === 200 && showMessage && showMessage(`Added plasmid ${plasmid.name}`)) // TODO: make snackbar
    setData?.(prev => prev.map(x => x.id === row.id ? {...x, volume: x.volume - miniprep_volume} : x))
    onCommit?.(row.id)
    handleClose()
  }
  const handleChange = (changes) => {
    setPlasmid(prev => ({...prev, ...changes}))
  }
  if (!open || !row)
    return null
  return (
    <AddFormForm
      open={open}
      title={`Miniprep ${getPlasmidLabel(row)}`}
      plasmid={plasmid}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      autoFocus="Concentration"
      {...props}
      />
  );
}

function BatchMiniprepForm({open, row : rows, onClose, setData, onCommit, showMessage, ...props}) {
  const [plasmid, setPlasmid] = useState({volume: 50 /*uL*/, date: moment()});
  const [concentrations, setConcentrations] = useState({})
  const getPlasmidLabel = (row) => row.colony_id ? `${row.name}-${row.colony_id}` : row.name
  const handleClose = () => {
    onClose();
    // don't reset plasmid. persist for easy batch processing
    setConcentrations({})
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const miniprep_volume = 1500 // TODO: assume 1.5mL culture used for miniprep
    axios.post("/api/wetlab/plasmids/addByMiniprep", {
      volume: plasmid.volume,
      date: plasmid.date?.toISOString(),
      concentrations: concentrations,
    })
      .then(({status}) => status === 200 && showMessage(rows.length === 1 ? `Added ${getPlasmidLabel(rows[0])}` : `Added ${rows.length} plasmids`)) // TODO: make snackbar
    onCommit?.(rows.map((row, i) => ({
      name: row.name,
      colony_id: row.colony_id,
      description: row.description,
      volume: plasmid.volume * 1000,
      concentration: 1000 * concentrations[row.id],
      date: plasmid.date?.toISOString(),
      source_id: row.id,
      miniprep_volume: miniprep_volume
    })))
    setData?.(prev => prev.map(x => rows.some(y => y.id === x.id) ? {...x, volume: x.volume - miniprep_volume, time_stop: moment()} : x))
    handleClose();
  }
  const handleChange = (changes) => {
    setPlasmid(prev => ({...prev, ...changes}))
  }
  if (!open || !rows)
    return null
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{rows.length === 1 ? `Miniprep ${getPlasmidLabel(rows[0])}` : `Miniprep ${rows.length} Plasmids`}</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY"
              label="Date"
              value={plasmid?.date || null}
              onChange={(newValue) => handleChange({date: newValue})}
            />
          </LocalizationProvider>
          <TextField
            margin="dense"
            label="Elution"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "decimal"}}
            sx={{input: {textAlign: "right"}}}
            value={plasmid?.volume || ""}
            onChange={e => handleChange({volume: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">μL</InputAdornment>,
            }}
          />
          <NanodropField
            samples={rows}
            concentrations={concentrations}
            onChangeConcentrations={setConcentrations}
            showMessage={showMessage}
          />
          <DialogContentText>Follow Qiagen protocol. Elute with {plasmid.volume}μL water. Use 1μL for nanodrop. Store in TBD box. Store leftover bacterial culture at 4°C.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Done</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function AddForm({open, onClose, onSubmit, row, ...props}) {
  const defualt_new_plate = () => ({name: "pSPS", time_start: moment(), temperature: 37, is_liquid: false, volume: 2000})
  const [plate, setPlate] = useState(defualt_new_plate());
  const handleClose = () => {
    onClose();
    setPlate(defualt_new_plate());
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (plate.is_liquid)
      onSubmit(plate)
    else
      onSubmit({...plate, volume: null, colony_id: null})
    handleClose();
  }
  const handleChange = (changes) => {
    setPlate(prev => ({...prev, ...changes}))
  }
  useEffect(() => {
    if (row?.name)
      handleChange({name: row.name})
    if (row?.description)
      handleChange({description: row.description})
    if (row?.colony_id)
      handleChange({colony_id: row.colony_id})
    if (row?.is_liquid)
      handleChange({is_liquid: row.is_liquid})
  }, [row])
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Bacterial Culture</DialogTitle>
        <DialogContent>
          <ToggleButtonGroup
            value={plate && plate.is_liquid}
            exclusive
            size="small"
            onChange={(_, value) => handleChange({is_liquid: value})}
          >
            <ToggleButton value={false}><CircleIcon />Plate</ToggleButton>
            <ToggleButton value={true}><ScienceIcon />Liquid</ToggleButton>
          </ToggleButtonGroup>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={plate?.name || ""}
            onChange={e => handleChange({name: e.target.value})}
          />
          {plate.is_liquid && <TextField
            margin="dense"
            label="Colony"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={plate?.colony_id || ""}
            onChange={e => handleChange({colony_id: e.target.value})}
          />}
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={plate?.description || ""}
            onChange={e => handleChange({description: e.target.value})}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={plate?.time_start || null}
              onChange={(newValue) => handleChange({time_start: newValue})}
            />
          </LocalizationProvider>
          <TextField
            margin="dense"
            label="Temperature"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={plate?.temperature || ""}
            onChange={e => handleChange({temperature: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
          {plate.is_liquid && <TextField
            margin="dense"
            label="Volume"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "decimal"}}
            sx={{input: {textAlign: "right"}}}
            value={plate?.volume || ""}
            onChange={e => handleChange({volume: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">μL</InputAdornment>,
            }}
          />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function Bacteria() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/bacteria/add",
        read: "/api/wetlab/bacteria/get",
        update: "/api/wetlab/bacteria/update",
        delete: "/api/wetlab/bacteria/discard"}}
      AddForm={AddForm}
      actions={[
        {
          label: "Pick Colonies",
          icon: <ColorizeIcon />,
          Form: PickColoniesForm,
          shouldShow: ({row}) => !row.is_liquid,
          multiple: true,
        },
        {
          label: "Miniprep",
          icon: <ZoomOutMapIcon />,
          Form: MiniprepForm,
          shouldShow: ({row}) => row.is_liquid
        },
        {
          label: "Miniprep Selected",
          icon: <ZoomOutMapIcon />,
          Form: BatchMiniprepForm,
          shouldShow: ({row}) => row.is_liquid,
          multiple: "only",
        },
        {
          label: "Save Glycerol Stock",
          icon: <AcUnitIcon />,
          Form: GlycerolStockForm,
          shouldShow: ({row}) => row.is_liquid,
        }
      ]}
      columns={[
        {
          field: 'is_liquid',
          headerName: "",
          width: 40,
          editable: false,
          renderCell: params => params.value ? <ScienceIcon /> : <CircleIcon />
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 180,
          editable: true,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'colony_id',
          headerName: 'Colony',
          type: 'number',
          width: 80,
          editable: false
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        {
          field: 'strain',
          headerName: 'Strain',
          width: 160,
          editable: true,
        },
        {
          field: 'time_start',
          type: 'dateTime',
          headerName: "Date",
          width: 100,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('time_start'),
          valueFormatter: value => value && moment(value).format("M/DD/YYYY"),
          sortComparator: nullableDateComparator,
        },
        {
          field: 'incubating',
          headerName: "Status",
          type: 'boolean',
          editable: true,
          width: 80,
          valueGetter: (_, row) => !row.time_stop,
          valueSetter: (value, row) => ({...row, time_stop: value ? null : moment().toISOString()}),
        },
        {
          field: 'time_elapsed',
          headerName: "Incubation",
          editable: false,
          width: 120,
          valueGetter: (_, row) => row.time_start && moment.duration(moment(row.time_stop || undefined) - moment(row.time_start)).humanize({h: 36}),
        },
        {
          field: 'temperature',
          type: 'number',
          headerName: "Temperature",
          editable: true,
          width: 100,
          valueFormatter: value => value && `${value}°C`
        },
        {
          field: 'volume',
          type: 'number',
          headerName: "Volume",
          editable: true,
          width: 100,
          valueFormatter: value => value && `${value} μL`
        },
      ]}
        initialState={{
          sorting: {
            sortModel: [
              { field: "incubating", sort: "desc" },
              { field: "time_start", sort: "desc" },
              { field: "is_liquid", sort: "desc" },
              { field: "name", sort: "asc" },
              { field: "colony_id", sort: "asc" }
            ],
          },
          columns: {
            columnVisibilityModel: { // not visible, other cols remain visible
              temperature: false,
              volume: false,
            },
          },
        }}
        slots={{
          booleanCellFalseIcon: AcUnitIcon,
          booleanCellTrueIcon: LocalFireDepartmentIcon
        }}
      />)
}
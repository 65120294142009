import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import { AddFormForm } from './Plasmids';
import { nullableDateComparator, sampleNameComparator, truncateString } from '../../utils/utils';
import { NANODROP_FORM_MENUACTION_DEF } from 'forms/NanodropForm';
import { useMediaQuery } from '@mui/material';
import { PLASMIDASSEMBLY_FORM_MENUACTION_DEF } from 'forms/PlasmidAssemblyForm';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import { SANGER_FORM_MENUACTION_DEF } from 'forms/SangerForm';
import { GOLDENGATE_FORM_MENUACTION_DEF } from 'forms/GoldenGateForm';

function AddForm({open, onClose, onSubmit, ...props}) {
  const [sample, setSample] = useState({name: "fSPS", date: moment(), volume: 20});
  const [isClosing, setIsClosing] = useState(false)
  const handleClose = () => {
    onClose();
  }
  useEffect(() => {
    if (!isClosing || open)
      return
    setSample(prev => {
      const match = prev.name.match?.(/^(.*\D)([0-9]+)$/)
      if (match?.length === 3)
        return {...prev, name: match[1] + (parseInt(match[2]) + 1).toString()}
      return prev
    })
    setIsClosing(false)
  }, [open, isClosing])
  const handleSubmit = (event) => {
    onSubmit(sample);
    handleClose();
    setIsClosing(true)
  }
  const handleChange = (changes) => {
    setSample(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title={"New DNA"}
      plasmid={sample}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isPlasmid={false}
      {...props}
      />
  );
}

export default function DNA() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const columns_hidden_mobile = isMobile && {description: false, volume: false}
  return (
    <>
    <SampleDataGrid
      api={{
        create: "/api/wetlab/dna/add",
        read: "/api/wetlab/dna/get",
        update: "/api/wetlab/dna/update",
        delete: "/api/wetlab/dna/discard",
        ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
      }}
      AddForm={AddForm}
      actions={[
        PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/dna/update"),
        NANODROP_FORM_MENUACTION_DEF("/api/wetlab/dna/update"),
        SANGER_FORM_MENUACTION_DEF,
        PLASMIDASSEMBLY_FORM_MENUACTION_DEF("/api/wetlab/protocols/assembly/create"),
        GOLDENGATE_FORM_MENUACTION_DEF,
      ]}
      columns={[
        {
          field: 'experiment',
          headerName: 'Experiment',
          width: 80,
          editable: true,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 120,
          editable: true,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        {
          field: 'sequence',
          headerName: 'Sequence',
          editable: true,
        },
        ...GRID_SAMPLE_LOCATION_COL_DEF(),
        {
          field: 'date',
          type: 'date',
          headerName: "Date",
          width: 100,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date'),
          valueFormatter: value => value && moment(value).format("M/D/YYYY"),
          sortComparator: nullableDateComparator,
        },
        {
          field: 'mass',
          headerName: "μg",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: (_, row) => row.concentration && row.volume && row.concentration * row.volume / 1000000000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1})
        },
        {
          field: 'concentration',
          headerName: "ng/μL",
          width: 80,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1, minimumFractionDigits: 1}),
          valueSetter: (value, row) => ({...row, concentration: value && value * 1000}),
        },
        {
          field: 'volume',
          headerName: "μL",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1}),
          valueSetter: (value, row) => ({...row, volume: value && value * 1000}),
        },
        {
          field: 'length',
          headerName: "bp",
          width: 60,
          editable: true,
          type: "number",
        }]}
        initialState={{
          sorting: {
            sortModel: [
              { field: "experiment", sort: "desc" },
              { field: "name", sort: "asc" },
              { field: "date", sort: "desc" },
            ],
          },
          columns: {
            columnVisibilityModel: {
              mass: false, // not visible, other cols remain visible
              length: false,
              sequence: false,
              ...columns_hidden_mobile
            },
          },
        }}
      getPrintLabel={(row) => [`${row.name}, ${(row.concentration / 1000)?.toFixed(1)}ng/μL`, row.description ? truncateString(row.description, 24) : "", `Simon ${moment(row.date).format("M/D/YYYY")}`]}
      />
    </>)
}
import React from 'react';
import { Grid, InputAdornment, TextField } from '@mui/material';

export default function PlasmidChoiceAndAmount({amount, unit, children, onChange}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        {children}
      </Grid>
      <Grid item xs={3}>
        <TextField
          margin="dense"
          label=" "
          fullWidth
          variant="standard"
          value={amount}
          InputProps={{
            endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
          }}
          inputProps={{inputMode: "numeric"}}
          sx={{input: {textAlign: "right"}}}
          aria-readonly={true}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

import React, { useEffect, useState } from 'react';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export default function TransformForm({row, open, onClose, showMessage, ...props}) {
  const [data, setData] = useState({strain: "NEB 5-alpha", time_start: moment(), temperature: 37})
  const [options_plasmids, setOptionsPlasmids] = useState()
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    handleClose()
    axios.post("/api/wetlab/bacteria/addByTransformation", data).then(() => showMessage?.("Added bacterial plate"))
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  useEffect(() => {
    if (row)
      setData(prev => ({...prev, plasmid: row}))
  }, [row])
  // Get plasmid options
  useEffect(() => {
    axios.get("/api/wetlab/plasmids/get").then(({data: {data}}) => setOptionsPlasmids(data))
  }, [])
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Transform Competent Cells</DialogTitle>
        <DialogContent>
          <DNASelector
            label="Plasmid"
            options={options_plasmids}
            concentrationUnit="ng/μL"
            onChange={(e, v) => handleChange({plasmid: v})}
            value={data?.plasmid || null}
          />
          <Autocomplete
            freeSolo
            autoHighlight
            autoSelect
            openOnFocus
            fullWidth
            options={["NEB 5-alpha", "NEB Turbo", "NEB Stable"]}
            renderInput={params => (
              <TextField
                {...params}
                margin="dense"
                label={"Strain"}
                variant="standard"
              />
            )}
            value={data?.strain || ""}
            onChange={(e, v) => handleChange({strain: v})}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={data?.time_start || null}
              onChange={(newValue) => handleChange({time_start: newValue})}
            />
          </LocalizationProvider>
          <TextField
            margin="dense"
            label="Temperature"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={data?.temperature || ""}
            onChange={e => handleChange({temperature: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">°C</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import React, {useState, useEffect} from 'react';
import moment from 'moment';
import SampleDataGrid from '../../layouts/SampleDataGrid';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  DialogContentText,
} from '@mui/material';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import RedoIcon from '@mui/icons-material/Redo';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CompressIcon from '@mui/icons-material/Compress';
import { formatNumberTCCells } from '../../utils/utils';
import TimelineIcon from '@mui/icons-material/Timeline';
import CultureHistoryForm from 'forms/CultureHistoryForm';
import axios from 'axios';
import PassageCellsForm from 'forms/PassageCellsForm';
import { AddCellPelletForm } from './CellPellet';
import { GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';

export function CryopreserveForm({open, row, onClose, setData, showMessage, ...props}) {
  const [aliquots, setAliquots] = useState(1);
  const [cells, setCells] = useState(1000000);
  if (!row)
    return null
  const volumeDiscard = aliquots * cells / (row.cells / row.volume)
  const handleClose = () => {
    onClose();
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    handleClose();
    // post to server
    const new_cryostock = {
      source_id: row.id,
      name: row.name,
      cells: cells,
      passage: row.passage + 1,
      date: moment().toISOString(),
      volume: 1000000,
      description: row.description,
    }
    axios.post("/api/wetlab/tc_basic/cryobank/add", {add: Array(aliquots).fill(new_cryostock), source_id: row.id, volume_discarded: volumeDiscard})
      .then(({data : {ids}}) => showMessage(`Added new cryostock${ids.length > 1 ? "s" : ""} ${ids.join(", ")}`))
    // eat up source
    setData(prev => prev.map(x => x.id !== row.id ? x : ({...x, cells: x.cells - cells, volume: x.volume - volumeDiscard, passage: x.passage + 1})))
  }
  const validateCells = () => cells * aliquots > row.cells
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      {row && (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Cryopreserve {row.name} Cells</DialogTitle>
          <DialogContent>
            <Grid container spacing={1} sx={{mb: 2}}>
              <Grid item>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Cells per Aliquot"
                  variant="standard"
                  inputProps={{inputMode: "numeric"}}
                  sx={{input: {textAlign: "right"}}}
                  value={cells}
                  error={validateCells()}
                  onChange={e => setCells(parseInt(e.target.value))}
                />
              </Grid>
              <Grid item>
                <TextField
                  margin="dense"
                  label="Aliquots"
                  variant="standard"
                  inputProps={{inputMode: "numeric"}}
                  sx={{input: {textAlign: "right"}}}
                  value={aliquots}
                  error={validateCells()}
                  onChange={e => setAliquots(parseInt(e.target.value))}
                />
              </Grid>
            </Grid>
            <DialogContentText>
              Spin down {(volumeDiscard.toLocaleString("en-US", {maximumFractionDigits: 1, useGrouping: false}))}μL culture.
              Resuspend in {aliquots}mL media + {75 * aliquots}μL DMSO.
              Distribute.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={validateCells()}>Done</Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

function SplitCellsForm({open, row, onClose, setData, ...props}) {
  const [volume, setVolume] = useState(500);
  const [cells, setCells] = useState(null);
  const [cellCulture, setCellCulture] = useState({date_seed: moment()});
  useEffect(() => {
    row && setCellCulture(prev => ({...prev, name: prev.name || row.name, description: prev.description || row.description}))
  }, [row])
  const handleChange = ({cells, volume, ...changes}) => {
    if (cells) {
      setCells(cells)
      setVolume(null)
    }
    else if (volume) {
      setVolume(volume)
      setCells(null)
    }
    else {
      setCellCulture(prev => ({...prev, ...changes}))
    }
  }
  const handleClose = () => {
    onClose();
  }
  const getVolumeFromCells = (cells) => (cells / (row.cells / row.volume)).toFixed(1)
  const getCellsFromVolume = (volume) => Math.round(volume * row.cells / row.volume);
  const getNewCellCulture = (cellCulture, volume, cells) => (
    {
      passage: row.passage + 1,
      ...cellCulture,
      volume: volume || getVolumeFromCells(cells),
      cells: volume ? getCellsFromVolume(volume) : cells
    })
  const handleSplitCells = (id, volume_change, newCellCulture) => {
    const density = row.cells / row.volume
    // Update on server
    Promise.all([
      // Adjust old cell culture
      axios.post("/api/wetlab/tc_basic/passage", {id, volumeDiscarded: volume_change, volumeAdded: 0, date: newCellCulture.date_seed.toISOString()}),
      // Add new cell culture
      axios.post("/api/wetlab/tc_basic/add", {...newCellCulture, date_seed: newCellCulture.date_seed.toISOString(), parent_id: id}),
    ])
    // Update locally
    setData(prev => [
      // Adjust old cell culture
      ...prev.map(x => x.id === id ? 
        {...x,
          volume: x.volume - volume_change,
          cells: x.cells - (volume_change * density),
        } : x
      ),
      // Add new cell culture
      {id: -1, ...newCellCulture, cells: newCellCulture?.cells}
      ]
    )
  }
  const handleSubmit = (event) => {
    handleSplitCells(row.id, volume || getVolumeFromCells(cells), getNewCellCulture(cellCulture, volume, cells));
    handleClose();
  }
  if (!row)
    return null
  return (
    <AddFormForm
      open={open}
      title={"Split Cells from " + row.name}
      cellCulture={getNewCellCulture(cellCulture, volume, cells)}
      error={{volume: (volume || getVolumeFromCells(cells)) > row.volume}}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

function AddForm({open, onClose, onSubmit, ...props}) {
  const default_cell_culture = {date_seed: moment()}
  const [cellCulture, setCellCulture] = useState(default_cell_culture);
  const handleClose = () => {
    onClose();
    setCellCulture(default_cell_culture);
  }
  const handleSubmit = () => {
    onSubmit(cellCulture);
    handleClose();
  }
  const handleChange = (changes) => {
    setCellCulture(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Cell Culture"
      cellCulture={cellCulture}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

function AddFormForm({open, onClose, onSubmit, cellCulture, onChange, title, error, ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={cellCulture?.name || ""}
            error={error && error.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={cellCulture?.description || ""}
            error={error && error.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <Grid container spacing={1}>
            <Grid item xs={4.5}>
              <TextField
                fullWidth
                margin="dense"
                label="Cells"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={cellCulture?.cells || ""}
                error={error && error.cells}
                onChange={e => onChange({cells: e.target.value})}
              />
            </Grid>
            <Grid item xs={4.5}>
              <TextField
                fullWidth
                margin="dense"
                label="Volume"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={cellCulture?.volume || ""}
                error={error && error.volume}
                onChange={e => onChange({volume: e.target.value})}
                InputProps={{
                  endAdornment: <InputAdornment position="end">μL</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="dense"
                label="Passage"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={cellCulture?.passage || ""}
                error={error?.passage}
                onChange={e => onChange({passage: e.target.value})}
              />
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={cellCulture?.date_seed || null}
              error={error && error.date_seed}
              onChange={(newValue) => onChange({date_seed: newValue})}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function CellCulture() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/tc_basic/add",
        read: "/api/wetlab/tc_basic/get",
        update: "/api/wetlab/tc_basic/update",
        delete: "/api/wetlab/tc_basic/aspirate"}}
      AddForm={AddForm}
      actions={[
        {
          label: "Split",
          icon: <CallSplitIcon />,
          Form: SplitCellsForm,
        },
        {
          label: "Passage",
          icon: <RedoIcon />,
          Form: PassageCellsForm,
        },
        {
          label: "Cryopreserve",
          icon: <AcUnitIcon />,
          Form: CryopreserveForm,
        },
        {
          label: "Pellet",
          icon: <CompressIcon />,
          Form: ({showMessage, setData, row, ...props}) => {
            const handleSubmit = (newRow) => {
              const newRowDate = newRow.date ? {date: moment(newRow.date).toISOString()} : {}
              axios.post("/api/wetlab/tc_basic/cellpellet/add", {...newRow, ...newRowDate})
                .then(({data : {id}}) => showMessage(`Saved cell pellet #${id}`))
              setData(prev => prev.map(x => x.id === row.id ? {...x, volume: x.volume - newRow.source.volume_discarded, cells: x.cells - newRow.cells} : x))
            }
            return (
              <AddCellPelletForm
                source={row}
                showMessage={showMessage}
                setData={setData}
                {...props}
                onSubmit={handleSubmit}
              />
              )
          },
        },
        {
          label: "History",
          icon: <TimelineIcon />,
          Form: CultureHistoryForm,
        }
      ]}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          width: 300,
          editable: true,
        },
        {
          field: 'density',
          headerName: "Density",
          width: 150,
          editable: true,
          type: "number",
          valueGetter: (_, row) => row.density || (row.volume && row.cells && Math.round(1000 * row.cells / row.volume)),
          valueSetter: (value, row) => ({...row,
            density: value,
            _cells: value * row.volume / 1000, // not updated by server because underscore
          }),
          valueFormatter: value => {
            if (!value) {
              return
            }
            else {
              return `${formatNumberTCCells(value)} / mL`
            }
          },
          sortingOrder: ["desc", "asc", null]
        },
        {
          field: 'passage',
          headerName: "Passage",
          width: 80,
          editable: false,
          type: "number",
          sortingOrder: ["desc", "asc", null]
        },
        {
          field: 'date_seed',
          type: 'dateTime',
          headerName: "Media age",
          width: 150,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date_seed'),
          valueFormatter: value => value && moment.duration(moment() - moment(value)).humanize({h: 36}),
        },
        {
          field: 'experiment',
          headerName: 'Experiment',
          width: 120,
          editable: true,
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        {
          field: 'cells',
          headerName: "Cells",
          width: 110,
          editable: false,
          type: "number",
          valueGetter: (_, row) => Math.round(row._cells || row.cells), // underscored from updating density
        },
        {
          field: 'volume',
          headerName: "μL",
          width: 100,
          editable: true,
          type: "number",
        }]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              experiment: false, // not visible, other cols remain visible
            },
          },
        }}
      />)
}
import React, { useEffect, useMemo, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useMatch,
  useNavigate,
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Containers from './pages/Containers';
import Tasks from './pages/Tasks';
import { Box, CssBaseline, AppBar, Toolbar, Typography, IconButton, useMediaQuery, GlobalStyles } from '@mui/material';
import { Home, Menu } from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ScienceIcon from '@mui/icons-material/Science';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import TaskIcon from '@mui/icons-material/Task';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo, generateLicense } from '@mui/x-license';
import moment from 'moment';
import CellCulture from './pages/samples/CellCulture';
import CryoBank from './pages/samples/CryoBank';
import Plasmids from './pages/samples/Plasmids';
import DNA from './pages/samples/DNA';
import Oligo from './pages/samples/Oligo';
import Bacteria from './pages/samples/Bacteria';
import GlycerolStock from './pages/samples/GlycerolStock';
import Search from './components/Search';
import Lentivirus from './pages/samples/Lentivirus';
import Generic from './pages/samples/Generic';
import ObjectSubtypesView from 'layouts/ObjectSubtypesView';
import PCR from 'pages/tasks/PCR';
import Assembly from 'pages/tasks/Assembly';
import Sanger from 'pages/tasks/Sanger';
import Digest from 'pages/tasks/Digest';
import Login from 'pages/Login';
import axios from 'axios';
import CellPellet from 'pages/samples/CellPellet';
import Anneal from 'pages/tasks/Anneal';
import Inventory from 'pages/tasks/Inventory';

export default function App() {
  // TODO: buy MUI-X license before production
  LicenseInfo.setLicenseKey(generateLicense({
    expiryDate: moment().add(10, "years").toDate(),
    licenseModel: "perpetual",
    orderNumber: "MUI-123",
    planScope: "premium",
    planVersion: "Q3-2024",
  }));
  const match = useMatch(":main?/:sub?")
  useEffect(() => {
    if (match?.params?.main === "samples") {
      document.title = "Samples | Finé";
    }
    else if (match?.params?.main === "containers") {
      document.title = "Containers | Finé";
    }
    else if (match?.params?.main === "tasks") {
      document.title = "Tasks | Finé";
    }
    else if (match?.params?.main === "login") {
      document.title = "Login | Finé";
    }
    else if (match?.params?.main === undefined) {
      document.title = "Dashboard | Finé"
    }
    else {
      document.title = "Finé"
    }
  }, [match]);
  const isPrintMedia = useMediaQuery('print')
  const theme = useMemo(() =>
      createTheme({
        palette: { mode: isPrintMedia ? 'light' : 'dark' },
        typography: {
          fontSize: isPrintMedia ? 11 : undefined,
        },
        spacing: isPrintMedia ? 2 : undefined,
        components: {
          MuiTableCell: {
            styleOverrides: {
              root: {
                padding: isPrintMedia ? 2 : undefined,
              },
            },
          },
          MuiStepConnector: {
            styleOverrides: {
              lineVertical: {
                minHeight: isPrintMedia ? 4 : undefined,
              },
            },
          },
        }
      }),
    [isPrintMedia],
  )
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        navigate("/login")
      }
      return Promise.reject(error)
    }
  )
  useEffect(() => {
    if (isMobile) {
      setDrawerOpen(false)
    }
  }, [isMobile])
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box sx={{ pt: 8, position: "fixed", height: "100%", width: "100%" }}>
          <CssBaseline enableColorScheme /> {/* enableColorScheme so that scrollbars look right in dark mode */}
          <GlobalStyles styles={{
            '@media print': {
              'div.hideOnPrint': {
                display: 'none',
              },
            },
          }} />
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="*" element={
                <>
                  <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                      <Routes>
                        <Route path="samples/:type" element={
                          isMobile && <IconButton color="inherit" onClick={() => setDrawerOpen(!drawerOpen)} aria-label="open navigation menu">
                            <Menu />
                          </IconButton>
                        }>
                        </Route>
                        <Route path="tasks/:type" element={
                          isMobile && <IconButton color="inherit" onClick={() => setDrawerOpen(!drawerOpen)} aria-label="open navigation menu">
                            <Menu />
                          </IconButton>
                        }>
                        </Route>
                      </Routes>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                      >
                        <Routes>
                          <Route path="/" element="Dashboard" />
                          <Route path="/containers" element="Containers" />
                          <Route path="samples">
                            <Route path=":type" element="Samples"/>
                            <Route path="" element="Samples"/>
                          </Route>
                          <Route path="tasks">
                            <Route path=":type" element="Tasks"/>
                            <Route path="" element="Tasks"/>
                          </Route>
                        </Routes>
                      </Typography>
                      <Search />
                      <IconButton color="inherit" onClick={() => navigate("/")} aria-label="dashboard">
                        <Home />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          if (match?.params?.main === "samples")
                            setDrawerOpen(!drawerOpen)
                          else {
                            navigate("/samples")
                            setDrawerOpen(true)
                          }
                        }}
                        aria-label="samples"
                      >
                        <CoronavirusIcon />
                      </IconButton>
                      <IconButton color="inherit" onClick={() => navigate("/containers")} aria-label="containers">
                        <ScienceIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          if (match?.params?.main === "tasks")
                            setDrawerOpen(!drawerOpen)
                          else {
                            navigate("/tasks")
                            setDrawerOpen(true)
                          }
                        }}
                        aria-label="tasks"
                      >
                        <TaskIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <Box sx={{height: "100%", overflowY: "auto"}}>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="samples" element={
                        <ObjectSubtypesView
                          views={{
                            "Mammalian": {"tc": "Cell Culture", "cryobank": "Cryobank", "pellet": "Cell Pellet", "lentivirus": "Lentivirus"},
                            "Nucleic Acid": {"plasmids": "Plasmids", "dna": "DNA", "oligo": "Oligos"},
                            "Bacterial": {"bacteria": "Bacteria", "glycerolstock": "Glycerol Stock"},
                            "Miscellaneous": {"generic": "Generic"},
                          }}
                          matchUrl="samples/:type"
                          mobileOpen={drawerOpen}
                          setMobileOpen={setDrawerOpen}
                        />}
                      >
                        <Route path="tc" element={<CellCulture />} />
                        <Route path="cryobank" element={<CryoBank />} />
                        <Route path="pellet" element={<CellPellet />} />
                        <Route path="lentivirus" element={<Lentivirus />} />
                        <Route path="plasmids" element={<Plasmids />} />
                        <Route path="generic" element={<Generic />} />
                        <Route path="dna" element={<DNA />} />
                        <Route path="oligo" element={<Oligo />} />
                        <Route path="bacteria" element={<Bacteria />} />
                        <Route path="glycerolstock" element={<GlycerolStock />} />
                        <Route path="" element={<Navigate to="tc" />} />
                      </Route>
                      <Route path="containers" element={<Containers />} />
                      <Route path="tasks" element={
                        <ObjectSubtypesView
                          matchUrl="tasks/:type"
                          views={{
                            "": {"generic": "Generic", "pcr": "PCR", "assembly": "Assembly", "sanger": "Sanger", "digest": "Digest", "anneal": "Anneal", "inventory": "Inventory"},
                          }}
                          mobileOpen={drawerOpen}
                          setMobileOpen={setDrawerOpen}
                        />}
                      >
                        <Route path="generic" element={<Tasks />} />
                        <Route path="pcr" element={<PCR />} />
                        <Route path="assembly" element={<Assembly />} />
                        <Route path="sanger" element={<Sanger />} />
                        <Route path="digest" element={<Digest />} />
                        <Route path="anneal" element={<Anneal />} />
                        <Route path="inventory" element={<Inventory />} />
                        <Route path="" element={<Navigate to="generic" />} />
                      </Route>
                    </Routes>
                  </Box>
                </>
              } />
            </Routes>
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

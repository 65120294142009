import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import { AddFormForm } from './Plasmids';
import { nullableDateComparator, sampleNameComparator, truncateString } from '../../utils/utils';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';

function AddForm({open, onClose, onSubmit, ...props}) {
  const [sample, setSample] = useState({name: "SPS", date: moment()});
  const [isClosing, setIsClosing] = useState(false)
  const handleClose = () => {
    onClose();
  }
  useEffect(() => {
    if (!isClosing || open)
      return
    setSample(prev => {
      const match = prev.name.match?.(/^(.*\D)([0-9]+)$/)
      if (match?.length === 3)
        return {...prev, name: match[1] + (parseInt(match[2]) + 1).toString()}
      return prev
    })
    setIsClosing(false)
  }, [open, isClosing])
  const handleSubmit = (event) => {
    onSubmit(sample);
    handleClose();
    setIsClosing(true)
  }
  const handleChange = (changes) => {
    setSample(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title={"New Generic Sample"}
      plasmid={sample}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isPlasmid={false}
      {...props}
      />
  );
}

export default function Generic() {
  return (
    <>
    <SampleDataGrid
      api={{
        create: "/api/wetlab/generic/create",
        read: "/api/wetlab/generic/read",
        update: "/api/wetlab/generic/update",
        delete: "/api/wetlab/generic/discard",
        ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
      }}
      AddForm={AddForm}
      actions={[
        PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/generic/update"),
      ]}
      columns={[
        {
          field: 'type',
          headerName: 'Type',
          width: 120,
          editable: true,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 120,
          editable: true,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        ...GRID_SAMPLE_LOCATION_COL_DEF(),
        {
          field: 'date',
          type: 'date',
          headerName: "Date",
          width: 100,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date'),
          valueFormatter: value => value && moment(value).format("M/D/YYYY"),
          sortComparator: nullableDateComparator,
        },
        {
          field: 'concentration',
          headerName: "Concentration",
          width: 80,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueSetter: (value, row) => ({...row, concentration: value && value * 1000}),
        },
        {
          field: 'volume',
          headerName: "Volume",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueSetter: (value, row) => ({...row, volume: value && value * 1000}),
        }]}
        initialState={{
          sorting: {
            sortModel: [
              { field: "date", sort: "desc" },
              { field: "type", sort: "asc" },
              { field: "name", sort: "asc" },
            ],
          },
        }}
      getPrintLabel={(row) => [`${row.type?.toUpperCase()} ${row.name}`, row.description ? truncateString(row.description, 24) : "", `Simon ${moment(row.date).format("M/D/YYYY")}`]}
      />
    </>)
}
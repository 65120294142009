import React from 'react'
import SampleDataGrid, { GRID_SAMPLE_DATE_COL_VAL } from 'layouts/SampleDataGrid'
import SangerForm from 'forms/SangerForm';
import { Chip, ListItemIcon, ListItemText, MenuItem, Select } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { GridActionsCellItem, GridEditModes, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-premium';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SyncIcon from '@mui/icons-material/Sync';
import SangerOrderForm from 'forms/SangerOrderForm';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { SEQUENCING_SERVICE_PROVIDERS, nullableDateComparator, sampleNameComparator } from 'utils/utils';
import HideSourceIcon from '@mui/icons-material/HideSource';
import moment from 'moment';
import Protocol from 'protocols/Protocol';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const STATUS_OPTIONS = [null, 'success', 'fail', 'ambiguous', 'ignore'];

export default function Sanger() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/protocols/sanger/create",
        read: "/api/wetlab/protocols/sanger/read",
        update: "/api/wetlab/protocols/sanger/update",
        delete: "/api/wetlab/protocols/sanger/discard"}}
      AddForm={SangerForm}
      actions={[
        {
          label: "Place Order",
          icon: <ShoppingCartIcon />,
          Form: SangerOrderForm,
          multiple: true,
        },
        {
          label: "Step by Step",
          icon: <FormatListNumberedIcon />,
          multiple: true,
          Form: Protocol,
        }
      ]}
      columns={[
        {
          field: 'result',
          headerName: 'Result',
          width: 150,
          editable: true,
          type: "singleSelect",
          valueOptions: STATUS_OPTIONS,
          renderCell: params => 
            // show chip with icon
            (params.value === "success" && <Chip icon={<CheckIcon />} label="Success" color="success" variant="outlined" size="small" />) || 
            (params.value === "fail" && <Chip icon={<CloseIcon />} label="Fail" color="error" variant="outlined" size="small" />) ||
            (params.value === "ambiguous" && <Chip icon={<QuestionMarkIcon />} label="Ambiguous" color="default" variant="outlined" size="small" />) ||
            (params.value === "ignore" && <Chip icon={<HideSourceIcon />} label="Ignore" color="default" variant="outlined" size="small" />) ||
            (params.value === null && <Chip icon={<SyncIcon />} label="Pending" color="warning" variant="outlined" size="small" />),
          renderEditCell: renderEditStatus,
          sortComparator: (v1, v2) => STATUS_OPTIONS.indexOf(v1) - STATUS_OPTIONS.indexOf(v2),
        },
        {
          field: 'sample',
          headerName: 'Sample',
          width: 160,
          editable: false,
          valueGetter: (_, row) => row.sample?.name,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'sample_col',
          headerName: 'Colony',
          width: 60,
          editable: false,
          valueGetter: (_, row) => row.sample?.colony_id
        },
        {
          field: 'primer',
          headerName: 'Primer',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.is_whole_plasmid ? "Whole-Plasmid" : row.primer?.name
        },
        {
          field: 'notes',
          headerName: 'Notes',
          minWidth: 120,
          flex: 1,
          editable: true,
        },
        {
          field: 'date_ordered',
          type: 'dateTime',
          headerName: "Ordered",
          width: 160,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date_ordered'),
          valueFormatter: value => value && moment(value).format("ddd M/D/YY ha"),
          sortComparator: nullableDateComparator,
        },
        {
          field: 'date_expected',
          type: 'dateTime',
          headerName: "Expected",
          width: 160,
          editable: false,
          ...GRID_SAMPLE_DATE_COL_VAL('date_expected'),
          valueFormatter: value => value && moment(value).format("ddd M/D/YY ha"),
          sortComparator: nullableDateComparator,
        },
        {
          field: 'service_provider',
          headerName: 'Provider',
          width: 120,
          editable: true,
          type: "singleSelect",
          valueOptions: SEQUENCING_SERVICE_PROVIDERS,
        },
        {
          field: 'order_id',
          headerName: 'Order ID',
          width: 120,
          editable: true,
        },
        {
          field: 'sample_index',
          headerName: 'Index',
          width: 60,
          editable: true,
        },
        {
          field: 'download',
          headerName: '',
          width: 50,
          type: "actions",
          getActions: params => {
            if (!params.row.order_id)
              return []
            if (params.row.service_provider === "Quintara")
              return [
                <GridActionsCellItem
                  icon={<DownloadIcon />}
                  label="Download"
                  onClick={() => window.open(`https://www.quintarabio.com/user/download?oid=${params.row.order_id}`)}
                />
              ]
            else
              return []
          }
        }
      ]}
      initialState={{
        sorting: {
          sortModel: [
            { field: "date_ordered", sort: "desc"},
            { field: "sample", sort: "asc" },
            { field: "sample_col", sort: "asc" },
            { field: "primer", sort: "asc" },
            { field: "result", sort: "asc" },
          ],
        },
        columns: {
          columnVisibilityModel: { // not visible, other cols remain visible
            date_expected: false,
          },
        },
      }}
    />
  )
}

function EditStatus(props) {
  const { id, value, field } = props;
  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();

  const handleChange = async (event) => {
    const isValid = await apiRef.current.setEditCellValue({ id, field, value: event.target.value });

    if (isValid && rootProps.editMode === GridEditModes.Cell) {
      apiRef.current.stopCellEditMode({ id, field, cellToFocusAfter: 'below' });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      apiRef.current.stopCellEditMode({ id, field, ignoreModifications: true });
    }
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      MenuProps={{
        onClose: handleClose,
      }}
      sx={{
        height: '100%',
        '& .MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          pl: 1,
        },
      }}
      autoFocus
      fullWidth
      open
    >
      {STATUS_OPTIONS.map((option) => {
        let IconComponent = null;
        if (option === 'fail') {
          IconComponent = CloseIcon
        } else if (option === 'success') {
          IconComponent = CheckIcon
        } else if (option === 'ambiguous') {
          IconComponent = QuestionMarkIcon
        } else if (option === 'ignore') {
          IconComponent = HideSourceIcon
        } else if (option === null) {
          IconComponent = SyncIcon
        }

        let label = option;
        if (option === 'fail') {
          label = 'Fail';
        } else if (option === 'success') {
          label = 'Success';
        } else if (option === 'ambiguous') {
          label = 'Ambiguous';
        } else if (option === 'ignore') {
          label = 'Ignore';
        } else if (option === null) {
          label = 'Pending';
        }

        return (
          <MenuItem key={option} value={option}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <IconComponent fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={label} sx={{ overflow: 'hidden' }} />
          </MenuItem>
        );
      })}
    </Select>
  );
}

function renderEditStatus(params) {
  return <EditStatus {...params} />;
}
import React, {useState, useEffect, useMemo} from 'react';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContentText,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { formatNumberPipettableVolume, formatNumberTCCells, nullableDateComparator, sampleNameComparator } from '../../utils/utils';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import SampleLocationPicker from 'components/SampleLocationPicker/SampleLocationPicker';
import SampleNameField from 'components/SampleNameField';
import IosShareIcon from '@mui/icons-material/IosShare';
import LyseCellsForm from 'forms/LyseCellsForm';

export function AddCellPelletForm({open, onClose, onSubmit, source, ...props}) {
  const default_cellPellet = {name: "SPS", cells: 1000000}
  const [_cellPellet, setCellPellet] = useState(default_cellPellet)
  const cellPellet = useMemo(() => (
    {..._cellPellet,
      source: {...source,
        volume_discarded: source?.volume && source?.cells && _cellPellet.cells && (source.volume * _cellPellet.cells / source.cells)}}
  ), [_cellPellet, source])
  useEffect(() => {
      if (open && !cellPellet?.date)
          setCellPellet(prev => ({...prev, date: moment()}))
  }, [open, cellPellet]);
  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    onSubmit(cellPellet)
    handleClose()
  }
  const handleChange = (changes) => {
    setCellPellet(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Cell Pellet"
      cellPellet={cellPellet}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

function AddFormForm({open, onClose, onSubmit, cellPellet, onChange, title, error, ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={cellPellet?.name || ""}
            error={error?.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={cellPellet?.description || ""}
            error={error?.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Cells"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={cellPellet?.cells || ""}
            error={error?.cells}
            onChange={e => onChange({cells: e.target.value})}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={cellPellet?.date || null}
              error={error?.date}
              onChange={(newValue) => onChange({date: newValue})}
            />
          </LocalizationProvider>
          <SampleLocationPicker
            location_box={cellPellet?.location_box}
            location_pos={cellPellet?.location_pos}
            onChange={onChange}
            sample_type="cell pellet"
          />
          <TextField
            margin="dense"
            label="Source"
            fullWidth
            disabled
            variant="standard"
            value={cellPellet?.source?.name || ""}
          />
          <DialogContentText>Spin down {cellPellet.source?.volume_discarded ? `${formatNumberPipettableVolume(cellPellet.source.volume_discarded)}` : `${formatNumberTCCells(cellPellet.cells)}`} cells. Snap freeze on dry ice.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default function CellPellet() {
  return (
    <>
      <SampleDataGrid
        api={{
          create: "/api/wetlab/tc_basic/cellpellet/add",
          read: "/api/wetlab/tc_basic/cellpellet/get",
          update: "/api/wetlab/tc_basic/cellpellet/update",
          delete: "/api/wetlab/tc_basic/cellpellet/discard",
          ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
        }}
        AddForm={AddCellPelletForm}
        actions={[
          PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/tc_basic/cellpellet/update"),
          {label: "Lyse", Form: LyseCellsForm, icon: <IosShareIcon />, multiple: true},
        ]}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
            sortComparator: sampleNameComparator,
          },
          {
            field: 'date',
            type: 'dateTime',
            headerName: "Date",
            width: 100,
            editable: true,
            ...GRID_SAMPLE_DATE_COL_VAL('date'),
            valueFormatter: value => value && moment(value).format("M/D/YYYY"),
            sortComparator: nullableDateComparator,
          },
          {
            field: 'description',
            headerName: 'Description',
            minWidth: 280,
            flex: 1,
            editable: true,
          },
          {
            field: 'cells',
            headerName: "Cells",
            width: 110,
            editable: true,
            type: "number",
          },
          ...GRID_SAMPLE_LOCATION_COL_DEF()
        ]}
          initialState={{
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }, { field: "name", sort: "asc" }, { field: "location_box", sort: "asc" }, { field: "location_pos", sort: "asc" }],
            },
          }}
        getPrintLabel={(row) => [row.name, `${formatNumberTCCells(row.cells)} cells`, `Simon ${moment(row.date).format("M/D/YYYY")}`]}
      />
    </>)
}

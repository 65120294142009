import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import AbcIcon from '@mui/icons-material/Abc';

export default function SangerForm({open, row, onClose, onSubmit, showMessage, ...props}) {
  const [data, setData] = useState()
  const [options_oligos, setOptionsOligos] = useState()
  const [options_dna, setOptionsDna] = useState()
  const [options_plasmids, setOptionsPlasmids] = useState()
  const options_templates = [...(options_dna || []), ...(options_plasmids || [])]
  const handleClose = () => {
    onClose()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (!data.samples?.length) {
      showMessage("Please select a sample")
      return
    }
    axios.post("/api/wetlab/protocols/sanger/create", {sample_ids: data.samples?.map(x => x.id), primer_ids: data.primers?.map(x => x.id)})
      .then(() => showMessage("Successfully added Sanger reactions"))
    handleClose()
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  // Get primer and template options
  useEffect(() => {
    axios.get("/api/wetlab/oligo/get").then(({data : {data}}) => setOptionsOligos(data))
  }, []);
  useEffect(() => {
    axios.get("/api/wetlab/dna/get").then(({data : {data}}) => setOptionsDna(data))
  }, []);
  useEffect(() => {
    axios.get("/api/wetlab/plasmids/get").then(({data : {data}}) => setOptionsPlasmids(data))
  }, []);
  useEffect(() => {
    setData(prev => ({...prev, samples: row}))
  }, [row])
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Sanger</DialogTitle>
        <DialogContent>
          <DNASelector
            label="Samples"
            options={options_templates}
            concentrationUnit="ng/μL"
            onChange={(e, v) => handleChange({samples: v})}
            value={data?.samples || []}
            multiple
          />
          <DNASelector
            label="Primers"
            options={options_oligos}
            concentrationUnit="μM"
            onChange={(e, v) => handleChange({primers: v})}
            value={data?.primers || []}
            multiple
          />
          <DialogContentText>
            Create {data?.samples?.length * (data?.primers?.length || 1)} Sanger sequencing reactions by pairing each sample with each primer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const SANGER_FORM_MENUACTION_DEF = {
  label: "Sanger",
  icon: <AbcIcon />,
  Form: SangerForm,
  multiple: true,
}

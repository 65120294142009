import React, { useState } from 'react';
import {
  Button,
  Dialog, DialogContent, DialogActions, DialogTitle,
  TextField,
} from '@mui/material';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_DATE_COL_VAL } from 'layouts/SampleDataGrid';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { SCHEDULETASK_FORM_MENUACTION_DEF } from 'forms/ScheduleTaskForm';
import Protocol from 'protocols/Protocol';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

export const calendar_format = {
  sameDay: 'h:mma',
  nextDay: '[Tomorrow] h:mma',
  nextWeek: 'ddd M/D h:mma',
  lastDay: '[Yesterday] h:mma',
  lastWeek: '[Last] ddd M/D h:mma',
  sameElse: 'M/D/YYYY h:mma'
}

function AddForm({open, onClose, onSubmit, ...props}) {
  const default_new_task = {time_scheduled: moment()}
  const [task, setTask] = useState(default_new_task);
  const handleClose = () => {
    onClose();
    setTask(default_new_task);
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(task)
    handleClose();
  }
  const handleChange = (changes) => {
    setTask(prev => ({...prev, ...changes}))
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Task</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={task?.title || ""}
            onChange={e => handleChange({title: e.target.value})}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              label="Scheduled Start"
              value={task?.time_scheduled || null}
              onChange={(newValue) => handleChange({time_scheduled: newValue})}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function Tasks() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/protocols/generic/create",
        read: "/api/wetlab/protocols/generic/read",
        update: "/api/wetlab/protocols/generic/update",
        delete: "/api/wetlab/protocols/generic/delete"}}
      AddForm={AddForm}
      actions={[
        SCHEDULETASK_FORM_MENUACTION_DEF("/api/wetlab/protocols/generic/update"),
        {
          label: "Step by Step",
          icon: <FormatListNumberedIcon />,
          multiple: true,
          Form: Protocol,
        }
      ]}
      columns={[
        {
          field: 'title',
          headerName: 'Title',
          flex: 1,
          minWidth: 180,
          editable: true,
        },
        {
          field: 'time_scheduled',
          headerName: 'Scheduled Start',
          width: 180,
          editable: true,
          type: 'dateTime',
          ...GRID_SAMPLE_DATE_COL_VAL('time_scheduled'),
          valueFormatter: value => value && moment(value).calendar(calendar_format),
        },
        {
          field: 'time_estimated',
          headerName: 'Scheduled Duration',
          width: 180,
          editable: true,
          type: 'number',
          valueGetter: value => value && moment.duration(value, "seconds").asMinutes(),
          valueFormatter: value => value && moment.duration(value, "minutes").humanize({m: 110}),
          valueSetter: (value, row) => ({...row, time_estimated: value && Math.round(value * 60)}),
        },
        {
          field: 'time_start',
          headerName: 'Actual Start',
          width: 180,
          editable: true,
          type: 'dateTime',
          ...GRID_SAMPLE_DATE_COL_VAL('time_start'),
          valueFormatter: value => value && moment(value).calendar(calendar_format),
        },
        {
          field: 'time_stop',
          headerName: 'Actual End',
          width: 180,
          editable: true,
          type: 'dateTime',
          ...GRID_SAMPLE_DATE_COL_VAL('time_stop'),
          valueFormatter: value => value && moment(value).calendar(calendar_format),
        },
      ]}
      initialState={{
        sorting: {
          sortModel: [{ field: "title", sort: "asc" }],
        },
        columns: {
          columnVisibilityModel: { // not visible, other cols remain visible
            // location: false,
          },
        },
      }}
    />
  )
}

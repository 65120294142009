import React from 'react';
import { Autocomplete, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import moment from 'moment';
import DNAVerified from './DNAVerified';

export default function DNASelector({options, label, concentrationUnit, error, helperText, ...props}) {
  const formatInfo = (text, final = false) => (
    <>
      <Typography
        sx={{ display: 'inline' }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        {text}
      </Typography>
      {!final && " • "}
    </>
  )
  return (
    <Autocomplete
      autoHighlight
      autoSelect
      openOnFocus
      fullWidth
      options={options || []}
      loading={options === undefined}
      getOptionLabel={option => option?.name || option}
      renderOption={(props, option) => (
        <ListItem dense {...props} key={option.id}>
          <ListItemText
            primary={
              <DNAVerified 
                name={option.name + (option.colony_id ? "-" + option.colony_id : "")}
                sequence_verified={option.sequence_verified}
                sequencing_notes={option.sequencing_notes}
              />
            }
            secondary={
              <>
                {option.date && formatInfo(moment(option.date).format("M/D/YY"), !option.concentration && !option.description)}
                {option.concentration && formatInfo((option.concentration / 1000).toFixed(0) + (concentrationUnit || ""), !option.description)}
                {option.description || ""}
              </>
            }
            secondaryTypographyProps={{style: {whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}}
          />
        </ListItem>
      )}
      isOptionEqualToValue={(option, value) => (option.id && value.id && (option.id === value.id)) || (option === value)} // direclty compare unless id is present
      renderInput={params => (
        <TextField
          {...params}
          margin="dense"
          label={label}
          variant="standard"
          error={error}
          helperText={helperText}
        />
      )}
      ChipProps={{size: "small", variant: "outlined"}}
      {...props}
    />
  )
}

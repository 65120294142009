import React, { useState } from 'react';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import NanodropField from 'components/NanodropField';
import axios from 'axios';

export default function NanodropForm({open, row : rows, onClose, setData, onCommit, showMessage, api_update, ...props}) {
  const [concentrations, setConcentrations] = useState({})
  const handleClose = () => {
    onClose();
    setConcentrations({})
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    const committed_data = Object.entries(concentrations).map(([key, val]) => ({id: key, concentration: 1000 * val}))
    axios.post(api_update, committed_data)
      .then(({status}) => status === 200 && showMessage("Concentrations saved"))
    onCommit?.(committed_data)
    setData?.(prev => prev.map(x => ({...x, concentration: (1000 * concentrations[x.id]) || x.concentration})))
    handleClose();
  }
  if (!open || !rows)
    return null
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{rows.length === 1 ? `Nanodrop ${rows[0].name}` : `Nanodrop ${rows.length} Samples`}</DialogTitle>
        <DialogContent>
          <NanodropField
            samples={rows}
            concentrations={concentrations}
            onChangeConcentrations={setConcentrations}
            showMessage={showMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Done</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const NANODROP_FORM_MENUACTION_DEF = (api_update) => ({
  label: "Nanodrop",
  icon: <TroubleshootIcon />,
  Form: (props) => <NanodropForm api_update={api_update} {...props} />,
  multiple: true,
})

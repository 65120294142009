import React, { useEffect, useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import NanodropField from 'components/NanodropField';
import axios from 'axios';
import SaveIcon from '@mui/icons-material/Save';

export default function NanodropStep({samples, ...props}) {
  const [concentrations, setConcentrations] = useState({})
  const [volume, setVolume] = useState(50)
  const handleShowMessage = x => setMessage(x)
  const [message, setMessage] = useState("")
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);
  const handleSubmit = (event) => {
    event.preventDefault()
    const committed_data = Object.entries(concentrations).map(([key, val]) => ({id: key, concentration: 1000 * val, volume: 1000 * volume}))
    axios.post('/api/wetlab/dna/update', committed_data)
      .then(({status}) => status === 200 && handleShowMessage("Concentrations saved"))
  }
  return (
    <>
      <TextField
        margin="dense"
        label="Elution Volume"
        fullWidth
        variant="standard"
        inputProps={{inputMode: "decimal"}}
        sx={{input: {textAlign: "right"}}}            
        value={volume || ""}
        onChange={e => setVolume(e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">μL</InputAdornment>,
        }}
      />
      <NanodropField
        samples={samples}
        concentrations={concentrations}
        onChangeConcentrations={setConcentrations}
        showMessage={handleShowMessage}
      />
      <Button onClick={handleSubmit} startIcon={<SaveIcon />} color="inherit">Save</Button>
      {message}
    </>
  )
}

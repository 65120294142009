import { gridStringOrNumberComparator } from "@mui/x-data-grid-premium"
import moment from "moment"

export function truncateString(str, maxlen) {
  if (str.length <= maxlen)
		return str
	const cutoffPhrase = str.indexOf(";")
	if (0 <= cutoffPhrase && cutoffPhrase <= maxlen)
		return str.slice(0, cutoffPhrase)
	const cutoff = str.lastIndexOf(" ", maxlen)
  return str.slice(0, cutoff >= 0 ? cutoff : maxlen) + "…"
}

export function formatNumberTCCells(cells) {
	if (!cells || isNaN(cells))
		return cells
	else if (cells < 950)
		return cells.toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 1})
	else if (cells < 9500)
		return (cells / 1000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 1}) + "k"
	else if (cells < 950000)
		return (cells / 1000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0}) + "k"
	else if (cells < 9500000)
		return (cells / 1000000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 1}) + "M"
	else if (cells < 950000000)
		return (cells / 1000000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0}) + "M"
	else
		return (cells / 1000000000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 1}) + "B"
}

export function formatNumberPipettableVolume(uL) {
	if (!uL || isNaN(uL))
		return uL
	else if (uL <= 0.5)
		return uL.toLocaleString("en-US", {maximumSignificantDigits: 2, useGrouping: false}) + "μL"
	else if (uL <= 1.7) // P2
		return uL.toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 2, useGrouping: false}) + "μL"
	else if (uL <= 200) // P10 or P20 or P200
		return uL.toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 1, useGrouping: false}) + "μL"
	else if (uL <= 2500) // P1000
		return uL.toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0, useGrouping: false}) + "μL"
	else if (uL <= 1200000)
		return (uL / 1000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 1, useGrouping: false}) + "mL"
	else
		return (uL / 1000000).toLocaleString("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 2, useGrouping: false}) + "L"
}

export function formatStringForGenewiz(str) {
	return str?.replace(/[^a-zA-Z0-9_]/g, "_") || ""
}

export function parseSampleName(str) {
	const re = /^(?<kind>[a-z])?SPS(?<experiment>[0-9]+(?=\.))?\.?(?<sample>[0-9]+)(?<subsample>[a-z]+)?/
	const match = str?.match(re)?.groups
	if (!match)
		return {sample: str}
	if (match.experiment) // normal basic sample like SPS123.4
		return {kind: match.kind, experiment: match.experiment && parseInt(match.experiment), sample: match.sample && parseInt(match.sample), subsample: match.subsample}
	if (match.kind) // important sample like pSPS36
		return {kind: match.kind, sample: match.sample && parseInt(match.sample), subsample: match.subsample}
	// just an experiment
	return {kind: match.kind, experiment: match.sample && parseInt(match.sample), subsample: match.subsample}
}

export function sampleNameComparator(a, b) {
	const x = parseSampleName(a)
	const y = parseSampleName(b)
	var result
	result = gridStringOrNumberComparator(x.experiment, y.experiment)
	if (result !== 0)
		return result
	result = (y.sample === undefined) - (x.sample === undefined)
		if (result !== 0)
			return result
	result = Number.isInteger(y.sample) - Number.isInteger(x.sample)
	if (result !== 0)
		return result
	result = gridStringOrNumberComparator(x.sample, y.sample)
	if (result !== 0)
		return result
	result = gridStringOrNumberComparator(x.subsample, y.subsample)
	if (result !== 0)
		return result
	result = gridStringOrNumberComparator(x.kind, y.kind)
	return result
}

function nullableComparator(a, b, null_first = true) {
	if (!a && !b)
		return 0
	if (!a)
		return null_first ? -1 : +1
	if (!b)
		return null_first ? +1 : -1
	return undefined
}

export function nullableDateComparator(a, b) {
	const result = nullableComparator(a, b)
	if (result !== undefined)
		return result
	return moment(a).startOf("day") - moment(b).startOf("day")
}

export function locationComparator(a, b) {
	// expect to have location_box and location_pos
	var result = nullableComparator(a, b, false)
	if (result !== undefined)
		return result
	result = nullableComparator(a?.location_box, b?.location_box, false)
	if (result !== undefined && result !== 0)
		return result
	result = gridStringOrNumberComparator(a?.location_box, b?.location_box)
	if (result !== 0)
		return result
	result = nullableComparator(a?.location_pos, b?.location_pos, false)
	if (result !== undefined && result !== 0)
		return result
	result = gridStringOrNumberComparator(a?.location_pos, b?.location_pos)
	return result
}

export function ssDnaMolWt(bp) { // TODO: do this server side
	return (bp * 607.4) + 157.9
}

export const SEQUENCING_SERVICE_PROVIDERS = ["Genewiz", "Quintara PlasmidExpress", "Genewiz (Copy to Clipboard)", "Quintara", "Plasmidsaurus"]

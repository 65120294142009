import React, { useEffect, useState } from 'react';
import {
  useNavigate,
} from "react-router-dom";
import { Box, Typography, IconButton, InputBase, Dialog, DialogContent, List, ListItemText, ListItemButton, useAutocomplete, LinearProgress, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { styled, alpha, useTheme } from '@mui/material/styles';
import axios from 'axios';

const sampletypes_to_pages = {
  glycerol_stock: "samples/glycerolstock",
  cryostock: "samples/cryobank",
  plasmid: "samples/plasmids",
  dna: "samples/dna",
  oligo: "samples/oligo",
  // samples/tc
  // samples/bacteria
}

// from https://mui.com/material-ui/react-app-bar/
const SearchBox = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.05),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.15),
  },
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  cursor: "text"
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled('div')(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  width: '16ch',
}));

function SearchDialog({open, onClose, ...props}) {
  const [searchResults, setSearchResults] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchOutcome, setSearchOutcome] = useState(null)
  useEffect(() => {
    if (searchQuery.length === 0)
    {
      setSearchResults(null)
      return
    }
    const timeout = setTimeout(() => {
      axios.get(`/api/wetlab/search?q=${searchQuery}`).then(({data}) => setSearchResults(data))
    }, 500) // only fetch after pause; same logic as form sample name field
    return () => clearTimeout(timeout);
  }, [searchQuery])
  const handleClose = () => {
    onClose()
    setSearchOutcome(null)
    setSearchQuery("")
  }
  const handleSearch = (outcome) => {
    handleClose()
    navigate(sampletypes_to_pages[outcome.type] + "?id=" + outcome.id)
  }
  const [openAutocomplete, setOpenAutocomplete] = useState(false)
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    options: searchResults || [],
    getOptionLabel: (option) => option.name,
    value: searchOutcome,
    inputValue: searchQuery,
    onChange: (e, val) => {e.preventDefault(); handleSearch(val)},
    onInputChange: (_, val) => setSearchQuery(val),
    filterOptions: (x) => x, // search as you type
    autoHighlight: true,
    blurOnSelect: true,
    clearOnEscape: false,
    clearOnBlur: false,
    open: openAutocomplete,
    onOpen: (e) => setOpenAutocomplete(true),
    onClose: () => {},
  })
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
	const loading = searchQuery.length > 0 && searchResults === null
  return (
    <Dialog open={!!open} onClose={handleClose} fullScreen={fullScreen} {...props}>
      <DialogContent sx={{width: 420, [theme.breakpoints.down('sm')]: {width: "100%"}}}>
        <Box sx={{display: "flex"}} {...getRootProps()}>
          <InputBase
            autoFocus
            margin="dense"
            placeholder="Search…"
            variant="standard"
            sx={{flexGrow: 1}}
            {...getInputProps()}
          />
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List aria-busy={loading} {...getListboxProps()}>
          {groupedOptions ? groupedOptions.map((option, index) => (
            <ListItemButton {...getOptionProps({option, index})} key={option.id} aria-label={option.name}>
              <ListItemText
                primary={option.name}
                secondary={<>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {option.type}
                  </Typography>
                  {option.description && " — " + option.description}
									{option.location && " (" + option.location + ")"}
                </>}
              />
            </ListItemButton>
          )) : null}
        </List>
        {loading && (
          <LinearProgress />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default function Search() {
  const [searchDialogOpen, setSearchDialogOpen] = useState(false)
	const theme = useTheme()
  return (
		<>
			{useMediaQuery(theme.breakpoints.down('sm')) ? (
				<IconButton color="inherit" onClick={e => {e.preventDefault(); setSearchDialogOpen(true)}}>
					<SearchIcon />
				</IconButton>
			) : (
				<SearchBox>
					<SearchIconWrapper>
						<SearchIcon />
					</SearchIconWrapper>
					<StyledInputBase onClick={e => {e.preventDefault(); setSearchDialogOpen(true)}}>
						<Typography variant="body2" color="darkgray">
							Search…
						</Typography>
					</StyledInputBase>
				</SearchBox>
			)}
			<SearchDialog open={searchDialogOpen} onClose={() => setSearchDialogOpen(false)} />
		</>
  );
}

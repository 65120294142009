import React, {useState} from 'react';
import moment from 'moment';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  DialogContentText,
  InputAdornment,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SampleNameField from 'components/SampleNameField';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';

export default function LyseCellsForm({open, onClose, row : rows, showMessage, ...props}) {
  const [sampleNames, setSampleNames] = useState([])
  const [volume, setVolume] = useState(50)
  const [date, setDate] = useState(moment())
  const handleSubmit = event => {
    event.preventDefault()
    const out = rows.map((row, i) => ({
      name: sampleNames[i],
      volume: volume * 1000,
      description: `extracted from ${row.name} (ID: ${row.id})`,
      date,
    }))
    axios.post("/api/wetlab/dna/add", out)
      .then(({status}) => status === 200 && showMessage?.(`Successfully created ${out.length} sample${out.length !== 1 ? "s" : ""}. Remember to discard pellet${out.length !== 1 ? "s" : ""}`))
    handleClose()
  }
  const handleClose = () => {
    onClose?.()
  }
  return (
    <Dialog open={!!open} onClose={handleClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Extract from Cell Pellet{rows?.length !== 1 && 's'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Create DNA samples</DialogContentText>
          {rows?.map?.((row, i) => (
            <SampleNameField
              key={row.id}
              autoFocus={i === 0}
              margin="dense"
              label={`${row.name} new sample name`}
              fullWidth
              variant="standard"
              value={sampleNames[i] || ""}
              onChange={e => setSampleNames(prev => [...prev.slice(0, i), e.target.value, ...prev.slice(i+1)])}
            />
          ))}
          <TextField
            margin="dense"
            label="Volume"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={volume || ""}
            onChange={e => setVolume(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end">μL</InputAdornment>,
            }}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY"
              label="Date"
              value={date || null}
              onChange={setDate}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

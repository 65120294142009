import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
} from '@mui/material';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import { sampleNameComparator } from '../../utils/utils';
import SampleLocationPicker from 'components/SampleLocationPicker/SampleLocationPicker';
import UploadIcon from '@mui/icons-material/Upload';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import SampleNameField from 'components/SampleNameField';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import AnnealOligosForm from 'forms/AnnealOligosForm';
import ImportOligosForm from 'forms/ImportOligosForm';

function AddForm({open, onClose, onSubmit, ...props}) {
  const defualt_new_plasmid = {name: "SPS"}
  const [plasmid, setPlasmid] = useState(defualt_new_plasmid);
  const handleClose = () => {
    onClose();
    setPlasmid(prev => ({location_box: prev?.location_box, ...defualt_new_plasmid}));
  }
  const handleSubmit = (event) => {
    onSubmit(plasmid);
    handleClose();
  }
  const handleChange = (changes) => {
    setPlasmid(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Oligo"
      plasmid={plasmid}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

export function AddFormForm({open, onClose, onSubmit, plasmid, onChange, title, error, autoFocus = "Name", ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <SampleNameField
            autoFocus={autoFocus === "Name"}
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={plasmid && plasmid.name}
            error={error && error.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <TextField
            autoFocus={autoFocus === "Sequence"}
            margin="dense"
            label="Sequence"
            fullWidth
            variant="standard"
            value={plasmid && plasmid.sequence}
            error={error && error.description}
            onChange={e => onChange({sequence: e.target.value})}
          />
          <TextField
            autoFocus={autoFocus === "Description"}
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={plasmid && plasmid.description}
            error={error && error.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <SampleLocationPicker location_box={plasmid?.location_box} location_pos={plasmid?.location_pos} onChange={onChange} sample_type="oligo" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function Oligo() {
  return (
    <>
    <SampleDataGrid
      api={{
        create: "/api/wetlab/oligo/add",
        read: "/api/wetlab/oligo/get",
        update: "/api/wetlab/oligo/update",
        delete: "/api/wetlab/oligo/discard",
        ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
      }}
      AddForm={AddForm}
      actions={[
        PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/oligo/update"),
        {
          label: "Import",
          icon: <UploadIcon />,
          Form: ImportOligosForm,
        },
        {
          label: "Anneal",
          icon: <MergeTypeIcon />,
          multiple: true,
          Form: AnnealOligosForm,
        }
      ]}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          width: 120,
          editable: true,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'concentration',
          headerName: "μM",
          width: 80,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1}),
          valueSetter: (value, row) => ({...row, concentration: value && value * 1000}),
        },
        ...GRID_SAMPLE_LOCATION_COL_DEF(),
        {
          field: 'date',
          type: 'date',
          headerName: "Date",
          width: 100,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date'),
        },
        {
          field: 'sequence',
          headerName: 'Sequence',
          width: 280,
          editable: true,
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        {
          field: 'length',
          headerName: "Length",
          width: 60,
          editable: false,
          type: "number",
          valueGetter: (_, row) => row.sequence && row.sequence.length,
        },
        {
          field: 'amount',
          headerName: "nmol",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: (_, row) => row.concentration && row.volume && row.concentration * row.volume / 1000000000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1})
        },
        {
          field: 'volume',
          headerName: "μL",
          width: 60,
          editable: true,
          type: "number",
          valueGetter: value => value && value / 1000,
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1}),
          valueSetter: (value, row) => ({...row, volume: value && value * 1000}),
        }]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              sequence: false, // not visible, other cols remain visible
              amount: false,
              length: false,
              date: false,
            },
          },
        }}
      getPrintLabel={oligo => [oligo.name, oligo.concentration ? `${oligo.concentration / 1000}μM` : "oligo", "Simon"]}
      />
    </>)
}

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
} from '@mui/material';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ScienceIcon from '@mui/icons-material/Science';
import CircleIcon from '@mui/icons-material/Circle';
import {AddForm as AddBacteriaForm} from './Bacteria'
import SampleLocationPicker from 'components/SampleLocationPicker/SampleLocationPicker';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';

function BaseInoculateForm({is_liquid, row, showMessage, ...props}) {
  const handleSubmit = (plate) => {
    axios.post("/api/wetlab/bacteria/add", {plate})
      .then(({status}) => status === 200 && showMessage(`Added ${plate.name}`))
  }
  return (
    <AddBacteriaForm onSubmit={handleSubmit} row={{name: row?.name, description: row?.description, colony_id: row?.colony_id, is_liquid: is_liquid}} {...props} />
  )
}

function InoculateCultureForm(props) {
  return <BaseInoculateForm is_liquid={true} {...props} />
}

function StreakPlateForm(props) {
  return <BaseInoculateForm is_liquid={false} {...props} />
}

function AddForm({open, onClose, onSubmit, ...props}) {
  const defualt_new_plasmid = {name: "pSPS", description: "", colony_id: null}
  const [plasmid, setPlasmid] = useState({...defualt_new_plasmid, volume: 1000});
  const handleClose = () => {
    onClose();
    setPlasmid(prev => ({...prev, location_pos: undefined, ...defualt_new_plasmid}));
  }
  const handleSubmit = (event) => {
    onSubmit(plasmid);
    handleClose();
  }
  const handleChange = (changes) => {
    setPlasmid(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Glycerol Stock"
      plasmid={plasmid}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

export function AddFormForm({open, onClose, onSubmit, plasmid, onChange, title, error, autoFocus = "Name", ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <SampleNameField
            autoFocus={autoFocus === "Name"}
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={plasmid?.name || ""}
            error={error?.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <TextField
            autoFocus={autoFocus === "Colony"}
            margin="dense"
            label="Colony"
            fullWidth
            inputProps={{inputMode: "numeric"}}
            variant="standard"
            value={plasmid?.colony_id || ""}
            error={error?.colony_id}
            onChange={e => onChange({colony_id: e.target.value})}
          />
          <TextField
            autoFocus={autoFocus === "Description"}
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={plasmid?.description || ""}
            error={error?.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Volume"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={plasmid?.volume || ""}
            onChange={e => onChange({volume: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">μL</InputAdornment>,
            }}
          />
          <SampleLocationPicker location_box={plasmid?.location_box} location_pos={plasmid?.location_pos} onChange={onChange} sample_type="glycerol_stock" />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={plasmid?.date || null}
              onChange={(newValue) => onChange({date: newValue})}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function GlycerolStock() {
  return (
    <>
    <SampleDataGrid
      api={{
        create: "/api/wetlab/bacteria/glycerolstock/add",
        read: "/api/wetlab/bacteria/glycerolstock/get",
        update: "/api/wetlab/bacteria/glycerolstock/update",
        delete: "/api/wetlab/bacteria/glycerolstock/discard",
        ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
      }}
      AddForm={AddForm}
      actions={[
        PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/bacteria/glycerolstock/update"),
        {
          label: "Inoculate Culture",
          icon: <ScienceIcon />,
          Form: InoculateCultureForm,
        },
        {
          label: "Streak Plate",
          icon: <CircleIcon />,
          Form: StreakPlateForm,
        },
      ]}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          width: 120,
          editable: true,
        },
        {
          field: 'colony_id',
          headerName: 'Colony',
          type: 'number',
          width: 80,
          editable: true,
        },
        ...GRID_SAMPLE_LOCATION_COL_DEF(),
        {
          field: 'date',
          type: 'date',
          headerName: "Date",
          width: 100,
          editable: true,
          ...GRID_SAMPLE_DATE_COL_VAL('date'),
          valueFormatter: value => value ? moment(value).format("M/D/YYYY") : "",
        },
        {
          field: 'description',
          headerName: 'Description',
          minWidth: 280,
          flex: 1,
          editable: true,
        },
        {
          field: 'volume',
          headerName: "μL",
          width: 60,
          editable: true,
          type: "number",
          valueFormatter: value => value?.toLocaleString("en-US", {maximumFractionDigits: 1})
        }]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      getPrintLabel={(row) => ["GLYCEROL STOCK", row.colony_id ? `${row.name}, colony ${row.colony_id}` : row.name, `Simon ${moment(row.date).format("M/D/YYYY")}`]}
      />
    </>)
}
import React, { useCallback, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  styled,
  DialogContentText,
  Typography,
} from '@mui/material';
import { formatStringForGenewiz } from '../utils/utils';
import { useDropzone } from 'react-dropzone'
import axios from 'axios';

function formatOligoForOrdering(oligo, vendor) {
  if (vendor === "Sigma")
    return [oligo.name, "", oligo.sequence, "", oligo.sequence.length <= 60 ? 0.025 : 0.05, "Desalt", "Dry", "None", 1]
  else if (vendor === "Genewiz")
    return [formatStringForGenewiz(oligo.name), oligo.sequence, "", "", "", 25]
  else {
    console.error(`Unknown vendor ${vendor}`)
    return
  }
}

function estimatedOligoCost(oligo, vendor) {
  if (vendor === "Sigma")
    return (oligo.sequence?.length <= 60 ? 0.15 : 0.22) * oligo.sequence?.length
  else if (vendor === "Genewiz")
    return 0.19 * oligo.sequence?.length
  else {
    console.error(`Unknown vendor ${vendor}`)
    return
  }
}

function parseInput(input) {
  const delimiter_row = /\r?\n|\r|\n/g
  const delimiter_col = /[,\t]/
  const rows = input.split(delimiter_row)
  const hasHeader = (rows[0].includes("Name") + rows[0].includes("Sequence") + rows[0].includes("Description")) > 2
  const header = hasHeader ? rows.shift().split(delimiter_col) /* remove first row */ : ["Name", "Description", "Sequence"]
  const headerIndName = header.indexOf("Name")
  const headerIndSequence = header.indexOf("Sequence")
  const headerIndDescription = header.indexOf("Description")
  const parseRow = (row) => {
    const array = row.split(delimiter_col)
    return {
      name: headerIndName >= 0 && array[headerIndName],
      sequence: headerIndSequence >= 0 && array[headerIndSequence],
      description: headerIndDescription >= 0 && array[headerIndDescription]
    }
  }
  return rows.filter(row => row !== "").map(row => parseRow(row))
}

export default function ImportOligosForm({open, onClose, onSubmit, showMessage, setData, ...props}) {
  const [input, setInput] = useState()
  const parsed = input ? parseInput(input) : []
  const onCopyToClipboard = (vendor) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(parsed.map(oligo => formatOligoForOrdering(oligo, vendor).join("\t")).join("\n"))
      showMessage(`Copied to clipboard, formatted for ${vendor}`)
    }
    else
      showMessage("Can't access clipboard")
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit?.(event)
    onClose?.()
    // post to server
    axios.post("/api/wetlab/oligo/add", parsed)
      .then(({data : {ids}}) => showMessage(`Saved ${ids.length} oligos`))
    // post to locally
    setData?.(prev => [...prev, ...parsed.map((oligo, i) => ({...oligo, id: -i}))])
  }
  const TableCellNoWrap = styled(TableCell)({
    whiteSpace: "nowrap",
  })
  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onerror = () => showMessage?.("Error reading file.")
    reader.onload = (event) => setInput(event.target.result)
    acceptedFiles.forEach(file => {
      if (file.type.split("/")[0] !== "text")
        showMessage(`Can't parse files of type ${file.type}`)
      reader.readAsText(file)
    });
  // because showMessage deps
  // eslint-disable-next-line
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth maxWidth="md" {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Batch of Oligos</DialogTitle>
        <DialogContent>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <TextField
              margin="dense"
              placeholder="Rows of Name, Description, and Sequence"
              fullWidth
              multiline
              minRows={2}
              maxRows={4}
              variant="outlined"
              value={input}
              onChange={e => setInput(e.target.value)}
              sx={{whiteSpace: "nowrap"}}
              focused={isDragActive || undefined} // force focus color or inherit focus state
            />
          </div>
          {parsed.length > 0 && <TableContainer><Table size="small">
            <TableHead>
              <TableRow>
                <TableCellNoWrap>Name</TableCellNoWrap>
                <TableCellNoWrap>Sequence</TableCellNoWrap>
                <TableCellNoWrap>Description</TableCellNoWrap>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsed.map((row, i) => 
                <TableRow key={i}>
                  <TableCellNoWrap>{row.name}</TableCellNoWrap>
                  <TableCellNoWrap>{row.sequence}</TableCellNoWrap>
                  <TableCellNoWrap>{row.description}</TableCellNoWrap>
                </TableRow>
              )}
            </TableBody>
          </Table></TableContainer>}
          <DialogContentText>
            <Typography variant="caption">
              {parsed.length} oligo{parsed.length === 1 ? '' : 's'} estimated to cost {parsed.reduce((cumulative, oligo) => cumulative + estimatedOligoCost(oligo, "Sigma"), 0).toLocaleString("en-US", {style: "currency", currency: "USD"})} (Sigma) or {parsed.reduce((cumulative, oligo) => cumulative + estimatedOligoCost(oligo, "Genewiz"), 0).toLocaleString("en-US", {style: "currency", currency: "USD"})} (Genewiz)
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCopyToClipboard("Sigma")}>Copy for Sigma</Button>
          <Button onClick={() => onCopyToClipboard("Genewiz")}>Copy for Genewiz</Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

import React, { useState } from 'react';
import axios from 'axios';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';

export default function ScheduleTaskForm({open, row : rows, onClose, setData, onCommit, showMessage, api_update, ...props}) {
  const [date, setDate] = useState(moment())
  const handleSubmit = e => {
    e?.preventDefault()
    axios.post(api_update, {id: rows.map(r => r.id), time_scheduled: date.toISOString()}).then(response => {
      if (response.status === 200) {
        showMessage?.("Scheduled")
      }
    })
    setData?.(prev => prev.map(row => rows.find(r => r.id === row.id) ? {...row, time_scheduled: date} : row))
    onClose?.()
  }
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Schedule {rows?.length} Task{rows?.length === 1 ? '' : 's'}</DialogTitle>
        <DialogContent>
          <DateTimePicker
            label="Scheduled Start"
            value={date}
            onAccept={setDate}
            slotProps={{ textField: { fullWidth: true, variant: 'standard', margin: 'dense' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Schedule</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const SCHEDULETASK_FORM_MENUACTION_DEF = (api_update) => ({
  label: "Schedule Task",
  icon: <ScheduleIcon />,
  Form: (props) => <ScheduleTaskForm api_update={api_update} {...props} />,
  multiple: true,
})

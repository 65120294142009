import React from 'react'
import SampleDataGrid from 'layouts/SampleDataGrid'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Protocol from 'protocols/Protocol';
import DigestForm from 'forms/DigestForm';
import { sampleNameComparator } from 'utils/utils';

export default function Digest() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/protocols/digest/create",
        read: "/api/wetlab/protocols/digest/read",
        update: "/api/wetlab/protocols/digest/update",
        delete: "/api/wetlab/protocols/digest/discard"}}
      AddForm={DigestForm}
      actions={[
        {
          label: "Step by Step",
          icon: <FormatListNumberedIcon />,
          multiple: true,
          Form: Protocol,
        }
      ]}
      columns={[
        {
          field: 'completed',
          headerName: 'Done',
          width: 40,
          editable: false,
          type: "boolean",
          valueGetter: (_, row) => !!row.time_stop
        },
        {
          field: 'product',
          headerName: 'Product',
          flex: 1,
          minWidth: 120,
          editable: false,
          valueGetter: (_, row) => row.product?.name,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'length',
          headerName: 'bp',
          headerAlign: "right",
          width: 80,
          editable: false,
          align: "right",
          valueGetter: (_, row) => row.product?.length
        },
        {
          field: 'fragment_sizes',
          headerName: 'Fragments',
          width: 240,
          editable: false,
          valueGetter: (_, row) => row.fragment_sizes?.join?.(", ")
        },
        {
          field: 'template',
          headerName: 'Template',
          width: 160,
          editable: false,
          valueGetter: (_, row) => row.template?.name
        },
        {
          field: 'enzymes',
          headerName: 'Enzymes',
          width: 240,
          editable: false,
          valueGetter: (_, row) => row.enzymes?.join?.(", ")
        },
        {
          field: 'incubation_temp',
          headerName: "Temperature",
          width: 100,
          editable: true,
          align: "right",
          valueFormatter: value => value && (value + "°C")
        },
        {
          field: 'incubation_time',
          headerName: "Time",
          width: 100,
          editable: true,
          align: "right",
          valueFormatter: value => value && (value + " sec")
        }
      ]}
      initialState={{
        sorting: {
          sortModel: [
            { field: "completed", sort: "asc" },
            { field: "product", sort: "asc" },
          ],
        },
      }}
    />
  )
}

import React from 'react'
import SampleDataGrid from 'layouts/SampleDataGrid'
import PCRForm from 'forms/PCRForm'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import Protocol from 'protocols/Protocol';
import { sampleNameComparator } from 'utils/utils';

export default function PCR() {
  return (
    <SampleDataGrid
      api={{
        create: "/api/wetlab/protocols/pcr/create",
        read: "/api/wetlab/protocols/pcr/read",
        update: "/api/wetlab/protocols/pcr/update",
        delete: "/api/wetlab/protocols/pcr/discard"}}
      AddForm={PCRForm}
      actions={[
        {
          label: "Step by Step",
          icon: <FormatListNumberedIcon />,
          multiple: true,
          Form: Protocol,
        }
      ]}
      columns={[
        {
          field: 'completed',
          headerName: 'Done',
          width: 40,
          editable: false,
          type: "boolean",
          valueGetter: (_, row) => !!row.time_stop
        },
        {
          field: 'product',
          headerName: 'Product',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.product?.name,
          sortComparator: sampleNameComparator,
        },
        {
          field: 'description',
          headerName: 'Description',
          flex: 1,
          editable: false,
          valueGetter: (_, row) => row.product?.description,
        },
        {
          field: 'template',
          headerName: 'Template',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.template?.name
        },
        {
          field: 'primer_fwd',
          headerName: 'Fwd Primer',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.primer_fwd?.name
        },
        {
          field: 'primer_rev',
          headerName: 'Rev Primer',
          width: 120,
          editable: false,
          valueGetter: (_, row) => row.primer_rev?.name
        },
        {
          field: 'temp_anneal',
          headerName: "Anneal",
          width: 100,
          editable: true,
          align: "right",
          valueFormatter: value => value && (value + "°C")
        },
        {
          field: 'time_extend',
          headerName: "Extend",
          width: 100,
          editable: true,
          align: "right",
          valueFormatter: value => value && (value + " sec")
        },
        {
          field: 'num_cycles',
          headerName: "Cycles",
          width: 100,
          align: "right",
          editable: true,
        },
        {
          field: 'length',
          headerName: "bp",
          width: 100,
          align: "right",
          editable: false,
          valueGetter: (_, row) => row.product?.length,
        },
      ]}
      initialState={{
        sorting: {
          sortModel: [
            { field: "completed", sort: "asc" },
            { field: "product", sort: "asc" },
          ],
        },
      }}
    />
  )
}

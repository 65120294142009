import React, { useState, useEffect } from 'react';
import LocationField from './LocationField';
import LocationPickerDialog from './LocationPickerDialog';
import axios from 'axios';

export default function SampleLocationPicker({location_box, location_pos, onChange, sample_type, ...props}) {
  const [containers, setContainers] = useState(null)
  const container = containers?.find(x => x.id === location_box)
  const [pickerView, setPickerView] = useState(null)
  useEffect(() => {
    axios.get(sample_type ? `/api/wetlab/container/read?sample_type=${sample_type}` : "/api/wetlab/container/read").then(({data : {data, recommend_sample_location}}) => {
      setContainers(data)
      if (recommend_sample_location) // force default value for the first load
        onChange(recommend_sample_location)
    })
  }, /* eslint-disable */[sample_type]/* eslint-enable */); // onChange dependency raises lint warnings, but it actually changes so frequently
  // TODO: this data can't be that big. just fetch it all at once.
  useEffect(() => {
    !!location_box && !!container && !container.position_occupied && 
      axios.get(`/api/wetlab/container/getAvailablePositions?id=${location_box}`)
      .then(({data : container}) => setContainers(prev => prev.map(container_prev => container_prev.id === location_box ? {...container_prev, ...container} : container_prev)))
  }, [location_box, container]);
  return (
    <>
      <LocationField
        location_box={container}
        location_pos={location_pos}
        onOpen={setPickerView}
        onClear={view => {
          if (view === "box")
            onChange({location_box: null})
          if (view === "position")
            onChange({location_pos: undefined})
        }}
        view={pickerView}
      />
      <LocationPickerDialog
        options={containers}
        location_box={container}
        location_pos={location_pos}
        view={pickerView}
        onChangeView={setPickerView}
        onChange={onChange}
      />
    </>
		)
}

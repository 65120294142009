import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText,
  TextField, InputAdornment, CircularProgress
} from '@mui/material';
import moment from 'moment';
import axios from 'axios';

export default function PickColoniesForm({open, row : rows, onClose, setData, onCommit, showMessage, ...props}) {
  const [volume, setVolume] = useState(2); // mL
  const [numColonies, setNumColonies] = useState(2);
  const [temperature, setTemperature] = useState(37)
  const [startNum, setStartNum] = useState();
  useEffect(() => {
    if (rows?.[0]?.id)
    {
      axios.get(`/api/wetlab/bacteria/colonies/count?ids=${rows.map(row => row.id).join(',')}`)
        .then(({data : {colony_num}}) => setStartNum((colony_num || 0) + 1))
      setTemperature(rows[0].temperature)
    }
    else
      setStartNum(null)
  }, [open, rows]);
  const handleClose = () => {
    onClose?.()
    // Reset default form values or persist values to simplify batch processing
  }
  const handlePickColonies = (ids, volume, startNum, numColonies) => {
    axios.post("/api/wetlab/bacteria/colonies/pick", {ids, volume: volume * 1000, startNum, numColonies, temperature})
    setData?.(prev => {
      const max_id = prev == null ? 0 : prev.reduce((cumVal, curVal) => Math.max(cumVal, curVal.id), -Infinity) // gets max id in data
      var temp_id = Math.floor(100000 * Math.random()) + max_id + 1 // +1 guarantees different from data, random makes unlikely collison with another add  
      const newData = rows.flatMap(row => 
        [...Array(numColonies).keys()].map(i => 
          ({...row,
            id: ++temp_id,
            time_start: moment(),
            time_stop: null,
            is_liquid: true,
            colony_id: startNum + i,
            volume: volume * 1000,
            temperature,
          })
        )
      )
      return [...prev.map(row => ids.includes(row.id) ? {...row, time_stop: moment()} : row), ...newData]
    }) // TODO: change temp_id to actual id from api return value
    onCommit?.(ids, volume * 1000, startNum, numColonies, temperature)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    handlePickColonies(rows.map(row => row.id), volume, startNum, numColonies)
    handleClose()
  }
  if (!open || !rows)
    return null
  return (
    <Dialog open={open} onClose={handleClose} {...props}>
      {rows && (
        <form onSubmit={handleSubmit}>
          <DialogTitle>Pick Colonies for {rows.length === 1 ? rows[0].name : `${rows.length} Cultures`}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Number of Colonies"
              fullWidth
              variant="standard"
              inputProps={{inputMode: "numeric"}}
              sx={{input: {textAlign: "right"}}}
              value={!isNaN(numColonies) ? numColonies : ""}
              onChange={e => setNumColonies(parseInt(e.target.value))}
            />
            <TextField
              margin="dense"
              label="Volume"
              fullWidth
              variant="standard"
              inputProps={{inputMode: "decimal"}}
              sx={{input: {textAlign: "right"}}}
              value={volume}
              onChange={e => setVolume(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">mL</InputAdornment>,
              }}
            />
            <TextField
              margin="dense"
              label="Temperature"
              fullWidth
              variant="standard"
              inputProps={{inputMode: "numeric"}}
              sx={{input: {textAlign: "right"}}}
              value={temperature}
              onChange={e => setTemperature(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">°C</InputAdornment>,
              }}
            />
            <DialogContentText>
                Master mix: add {rows.length * numColonies * volume}μL amp to {rows.length * numColonies * volume}mL teriffic broth.
                Number colonies {startNum !== null ? startNum : <CircularProgress size={12} />} through {startNum !== null ? startNum + numColonies - 1 : <CircularProgress size={12} />}.
                Incubate at {temperature}°C overnight.
                Store plate at 4°C.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={startNum === null}>Done</Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
}

import React, { useCallback } from 'react';
import {TextField, InputAdornment} from '@mui/material';
import { useDropzone } from 'react-dropzone';

export default function NanodropField({concentrations, onChangeConcentrations, samples, showMessage}) {
  const getPlasmidLabel = (row) => row.colony_id ? `${row.name}-${row.colony_id}` : row.name
  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.onerror = () => showMessage?.("Error reading file.")
    reader.onload = (event) => {
      // Parse file
      const fileRows = event.target.result.split(/\r?\n|\r|\n/g);
      const headerRow = fileRows[0].split("\t");
      const dataRows = fileRows.slice(1).map(row => row.split("\t"));
      // Align columns to expected
      const concColIdx = headerRow.indexOf("Nucleic Acid(ng/uL)")
      const newConcs = Object.fromEntries(samples.map(({id : row_id}, i) => [row_id, dataRows[i][concColIdx]]))
      onChangeConcentrations(prev => ({...prev, ...newConcs}))
    };
    acceptedFiles.forEach(file => reader.readAsText(file));
  // because onChangeConcentrations and showMessage deps
  // eslint-disable-next-line
  }, [samples])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})
  if (!samples)
    return null
  return (
      <div {...getRootProps()}>
				<input {...getInputProps()} />
				{samples.map((row, i) => (
					<TextField
						key={row.id}
						margin="dense"
						label={`Concentration of ${getPlasmidLabel(row)}`}
						fullWidth
						variant="standard"
						inputProps={{inputMode: "decimal"}}
						sx={{input: {textAlign: "right"}}}
						value={concentrations?.[row.id] || ""}
						onChange={e => onChangeConcentrations(prev => ({...prev, [row.id]: e.target.value}))}
						InputProps={{
							endAdornment: <InputAdornment position="end">ng/μL</InputAdornment>,
						}}
						autoFocus={i === 0}
						focused={isDragActive || undefined} // force focus color or inherit focus state
					/>))
				}
      </div>
  );
}

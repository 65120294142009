import React, {useState, useEffect} from 'react';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { formatNumberTCCells } from '../../utils/utils';
import SampleLocationPicker from '../../components/SampleLocationPicker/SampleLocationPicker';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';

function AddForm({open, onClose, onSubmit, ...props}) {
  const default_cellCulture = {volume: 1000}
  const [cellCulture, setCellCulture] = useState(default_cellCulture);
  useEffect(() => {
      if (open && !cellCulture?.date) {
          setCellCulture(prev => ({...prev, date: moment()}))
      }
  }, [open, cellCulture]);
  const handleClose = () => {
    onClose()
  }
  const handleSubmit = () => {
    onSubmit(cellCulture);
    handleClose();
  }
  const handleChange = (changes) => {
    setCellCulture(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Cryostock"
      cellCulture={cellCulture}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

function AddFormForm({open, onClose, onSubmit, cellCulture, onChange, title, error, ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={cellCulture?.name || ""}
            error={error?.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={cellCulture?.description || ""}
            error={error?.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Passage"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            value={cellCulture?.passage || ""}
            error={error?.passage}
            onChange={e => onChange({passage: e.target.value})}
          />
          <Grid container spacing={1}>
            <Grid item>
              <TextField
                margin="dense"
                label="Cells"
                variant="standard"
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                value={cellCulture?.cells || ""}
                error={error?.cells}
                onChange={e => onChange({cells: e.target.value})}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                label="Volume"
                variant="standard"
                inputProps={{inputMode: "decimal"}}
                sx={{input: {textAlign: "right"}}}
                value={cellCulture?.volume || ""}
                error={error?.volume}
                onChange={e => onChange({volume: e.target.value})}
                InputProps={{
                  endAdornment: <InputAdornment position="end">μL</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={cellCulture?.date || null}
              error={error?.date}
              onChange={(newValue) => onChange({date: newValue})}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export function MoveToLn2Form({open, onClose, onSubmit, row, showMessage, setData, onCommit, ...props}) {
  const default_changes = () => ({date_ln2: moment()})
  const [changes, setChanges] = useState(default_changes())
  if (!row | !open)
    return null
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit?.(event)
    // post to server
    axios.post("/api/wetlab/tc_basic/cryobank/update", {id: row.id, date_ln2: changes.date_ln2?.toISOString(), location_box: changes.location_box, location_pos: changes.location_pos})
    // post to locally
    setData?.(prev => prev.map(x => x.id !== row.id ? x : ({...x, ...changes})))
    onCommit?.(row.id)
    handleClose()
  }
  const handleChange = (changes) => {
    setChanges(prev => ({...prev, ...changes}))
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Move {row.name} to LN2</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={changes?.date_ln2 || null}
              onChange={(newValue) => handleChange({date_ln2: newValue})}
            />
          </LocalizationProvider>
          <SampleLocationPicker location_box={changes?.location_box} location_pos={changes?.location_pos} onChange={handleChange} sample_type="cryostock" />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function ThawForm({open, onClose, onSubmit, row, showMessage, setData, onCommit, ...props}) {
  const [changes, setChanges] = useState({date_thaw: moment()})
  if (!row | !open)
    return null
  const handleClose = () => {
    onClose && onClose()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit && onSubmit(event)
    handleClose()
    // post to server
    axios.post("/api/wetlab/tc_basic/cryobank/thaw", {id: row.id, date_thaw: changes.date_thaw?.toISOString(), volume: changes.volume})
      .then(({data : {id}}) => showMessage(`Thawed into cell culture ${id}`))
    // post to locally
    setData?.(prev => prev.filter(x => x.id !== row.id))
  }
  const handleChange = (changes) => {
    setChanges(prev => ({...prev, ...changes}))
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Thaw {row.name}</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={changes?.date_thaw || null}
              onChange={(newValue) => handleChange({date_thaw: newValue})}
            />
          </LocalizationProvider>
          <TextField
            autoFocus
            margin="dense"
            label="Volume"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "numeric"}}
            sx={{input: {textAlign: "right"}}}
            value={changes?.volume || ""}
            onChange={e => handleChange({volume: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">μL</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function CryoBank() {
  return (
    <>
      <SampleDataGrid
        api={{
          create: "/api/wetlab/tc_basic/cryobank/add",
          read: "/api/wetlab/tc_basic/cryobank/get",
          update: "/api/wetlab/tc_basic/cryobank/update",
          delete: "/api/wetlab/tc_basic/cryobank/discard",
          ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
        }}
        AddForm={AddForm}
        actions={[
          PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/tc_basic/cryobank/update"),
          {
            label: "Move to LN2",
            icon: <AcUnitIcon />,
            Form: MoveToLn2Form,
            shouldShow: (params) => !params.row.date_ln2,
          },
          {
            label: "Thaw",
            icon: <LocalFireDepartmentIcon />,
            Form: ThawForm,
            shouldShow: (params) => params.row.date_ln2 && !params.row.date_thaw,
          },
        ]}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
          },
          {
            field: 'passage',
            headerName: "Passage",
            width: 80,
            editable: true,
            type: "number",
            sortingOrder: ["desc", "asc", null]
          },
          {
            field: 'date',
            type: 'dateTime',
            headerName: "Date on Tube",
            width: 100,
            editable: true,
            ...GRID_SAMPLE_DATE_COL_VAL('date'),
            valueFormatter: value => value && moment(value).format("M/D/YYYY")
          },
          {
            field: 'date_ln2',
            type: 'dateTime',
            headerName: "Date into LN2",
            width: 100,
            editable: true,
            valueGetter: value => value && new Date(value),
            valueFormatter: value => value && moment(value).format("M/D/YYYY")
          },
          {
            field: 'date_thaw',
            type: 'dateTime',
            headerName: "Date Removed",
            width: 100,
            editable: true,
            valueGetter: value => value && new Date(value),
            valueFormatter: value => value && moment(value).format("M/D/YYYY")
          },
          {
            field: 'description',
            headerName: 'Description',
            minWidth: 280,
            flex: 1,
            editable: true,
          },
          {
            field: 'cells',
            headerName: "Cells",
            width: 110,
            editable: true,
            type: "number",
          },
          {
            field: 'volume',
            headerName: "μL",
            width: 100,
            editable: true,
            type: "number",
            valueGetter: value => value && value / 1000,
            valueSetter: (value, row) => ({...row, volume: value && value * 1000}),
          },
          ...GRID_SAMPLE_LOCATION_COL_DEF()
        ]}
          initialState={{
            sorting: {
              sortModel: [{ field: "location_box", sort: "asc" }, { field: "location_pos", sort: "asc" }, { field: "name", sort: "asc" }],
            },
            columns: {
              columnVisibilityModel: {
                volume: false, // not visible, other cols remain visible
                date_thaw: false,
              },
            },
          }}
        getPrintLabel={(row) => [row.name, `${formatNumberTCCells(row.cells)} cells, p${row.passage}`, `Simon ${moment(row.date).format("M/D/YYYY")}`]}
      />
    </>)
}
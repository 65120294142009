import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';
import PlasmidChoiceAndAmount from 'components/PlasmidChoiceAndAmount';
import DataUsageIcon from '@mui/icons-material/DataUsage';

const VECTOR_PMOL = 0.01
const INSERT_PMOL = 0.03
const ENZYMES = [
  {id: "goldengate_esp3i", name: "Esp3I", description: "CGTCTC(1/5)^"},
  {id: "goldengate_bpii", name: "BpiI", description: "GAAGAC(2/6)^"},
]

export default function GoldenGateForm({open, onClose, onSubmit, showMessage, row, ...props}) {
  const [data, setData] = useState({
    name: "pSPS",
    enzyme: ENZYMES[0],
  })
  const [options_vectors, setOptionsVectors] = useState()
  const [options_inserts, setOptionsInserts] = useState()
  const [insert_pmol, setInsertPmol] = useState(INSERT_PMOL)
  const [vector_pmol, setVectorPmol] = useState(VECTOR_PMOL)
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    axios.post("/api/wetlab/protocols/assembly/create", {
      method: data.enzyme?.id,
      product: {
        name: data.name,
        sequence: data.sequence,
      },
      fragments: {
        [data.vector.id]: vector_pmol,
        [data.insert.id]: insert_pmol,
      },
      volume: 10,
      incubation_time: 60,
    })
      .then(({status}) => status === 200 && showMessage("Plasmid assembly saved"))
    handleClose();
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  // Load data from row (plasmid)
  useEffect(() => {
    if (!row)
      return
    setData(prev => ({
      ...prev,
      ...(row.type_friendly === "Plasmid" ? {vector: row} : {insert: row}),
    }))
  }, [row])
  // Get plasmid options
  useEffect(() => {
    axios.get("/api/wetlab/plasmids/get").then(({data: {data}}) => setOptionsVectors(data))
  }, [])
  // Get enzyme options
  useEffect(() => {
    axios.get("/api/wetlab/dna/get").then(({data : {data}}) => setOptionsInserts(data))
  }, [])
  // Get recommended plasmid sequence
  useEffect(() => {
    if (!data.vector?.id || !data.insert?.id || !data.enzyme?.id) {
      handleChange({sequence: ""})
      return
    }
    axios.get(`/api/wetlab/protocols/assembly/params?method=${data.enzyme.id}&vector=${data.vector.id}&insert=${data.insert.id}`).then(({data : {error, sequence}}) => {
      if (error)
        handleChange({sequence: ""})
      else
        handleChange({sequence})
    })
  }, [data.vector, data.insert, data.enzyme])
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Golden Gate Assembly</DialogTitle>
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={data?.name || ""}
            onChange={e => handleChange({name: e.target.value})}
          />
          <PlasmidChoiceAndAmount amount={vector_pmol} unit="pmol" onChange={e => setVectorPmol(e.target.value)}>
            <DNASelector
              label="Vector"
              options={options_vectors}
              concentrationUnit="ng/μL"
              onChange={(e, v) => handleChange({vector: v})}
              value={data?.vector || null}
            />
          </PlasmidChoiceAndAmount>
          <PlasmidChoiceAndAmount amount={insert_pmol} unit="pmol" onChange={e => setInsertPmol(e.target.value)}>
            <DNASelector
              label="Insert"
              options={options_inserts}
              concentrationUnit="ng/μL"
              onChange={(e, v) => handleChange({insert: v})}
              value={data?.insert || null}
            />
          </PlasmidChoiceAndAmount>
          <DNASelector
            label="Enzyme"
            options={ENZYMES}
            onChange={(e, v) => handleChange({enzyme: v})}
            value={data?.enzyme || null}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={data?.description || ""}
            onChange={e => handleChange({description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Sequence"
            fullWidth
            variant="standard"
            value={data?.sequence || ""}
            onChange={e => handleChange({sequence: e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export const GOLDENGATE_FORM_MENUACTION_DEF = {
  label: "Golden Gate",
  icon: <DataUsageIcon />,
  Form: (props) => <GoldenGateForm {...props} />,
}

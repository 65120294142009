import React, {useState, useEffect} from 'react';
import moment from 'moment';
import SampleDataGrid, { GRID_SAMPLE_LOCATION_COL_DEF, GRID_SAMPLE_DATE_COL_VAL } from '../../layouts/SampleDataGrid';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SampleLocationPicker from 'components/SampleLocationPicker/SampleLocationPicker';
import { PUTAWAY_FORM_MENUACTION_DEF } from 'forms/PutAwayForm';
import SampleNameField from 'components/SampleNameField';
import Protocol from 'protocols/Protocol';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

function AddForm({open, onClose, onSubmit, ...props}) {
  const default_cellCulture = {volume: 2000, date: moment()}
  const [cellCulture, setCellCulture] = useState(default_cellCulture);
  useEffect(() => {
      if (open && !cellCulture?.date) {
          setCellCulture(prev => ({...prev, date: moment()}))
      }
  }, [open, cellCulture]);
  const handleClose = () => {
    onClose();
    setCellCulture(prev => ({location_box: prev?.location_box, ...default_cellCulture}));
  }
  const handleSubmit = () => {
    onSubmit(cellCulture);
    handleClose();
  }
  const handleChange = (changes) => {
    setCellCulture(prev => ({...prev, ...changes}))
  }
  return (
    <AddFormForm
      open={open}
      title="New Lentivirus"
      cellCulture={cellCulture}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={handleClose}
      {...props}
      />
  );
}

function AddFormForm({open, onClose, onSubmit, cellCulture, onChange, title, error, ...props}) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <Dialog open={!!open} onClose={onClose} {...props}>
      <form onSubmit={handleSubmit}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            variant="standard"
            value={cellCulture && cellCulture.name}
            error={error && error.name}
            onChange={e => onChange({name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={cellCulture && cellCulture.description}
            error={error && error.description}
            onChange={e => onChange({description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Volume"
            fullWidth
            variant="standard"
            inputProps={{inputMode: "decimal"}}
            sx={{input: {textAlign: "right"}}}
            value={cellCulture && cellCulture.volume}
            error={error && error.volume}
            onChange={e => onChange({volume: e.target.value})}
            InputProps={{
              endAdornment: <InputAdornment position="end">μL</InputAdornment>,
            }}
          />
          <SampleLocationPicker location_box={cellCulture?.location_box} location_pos={cellCulture?.location_pos} onChange={onChange} sample_type="lentivirus" />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              slotProps={{
                textField: {
                  margin: "dense",
                  fullWidth: true,
                  variant: "standard"
                }
              }}
              format="M/D/YY h:mm A"
              label="Date"
              value={cellCulture?.date}
              error={error && error.date}
              onChange={(newValue) => onChange({date: newValue})}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default function Lentivirus() {
  return (
    <>
      <SampleDataGrid
        api={{
          create: "/api/wetlab/tc_basic/lentivirus/add",
          read: "/api/wetlab/tc_basic/lentivirus/get",
          update: "/api/wetlab/tc_basic/lentivirus/update",
          delete: "/api/wetlab/tc_basic/lentivirus/discard",
          ubiquitinylate: "/api/wetlab/generic/ubiquitinylate",
        }}
        AddForm={AddForm}
        actions={[
          PUTAWAY_FORM_MENUACTION_DEF("/api/wetlab/tc_basic/lentivirus/update"),
          {
            label: "Step by Step",
            icon: <FormatListNumberedIcon />,
            multiple: true,
            Form: ({row, ...props}) => <Protocol row={row?.map?.(x => ({id: x.production_task_id}))} {...props} />,
          }
        ]}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
          },
          {
            field: 'plasmid_transfer',
            headerName: 'Plasmid',
            width: 200,
            editable: false,
            valueFormatter: value => value?.name,
          },
          {
            field: 'date',
            type: 'dateTime',
            headerName: "Date",
            width: 100,
            editable: true,
            ...GRID_SAMPLE_DATE_COL_VAL('date'),
            valueFormatter: value => value && moment(value).format("M/D/YYYY")
          },
          ...GRID_SAMPLE_LOCATION_COL_DEF(),
          {
            field: 'description',
            headerName: 'Description',
            minWidth: 280,
            flex: 1,
            editable: true,
          },
          {
            field: 'plasmid_packaging',
            headerName: 'Packaging Plasmid',
            width: 160,
            editable: false,
            valueFormatter: value => value?.name,
          },
          {
            field: 'plasmid_envelope',
            headerName: 'Envelope Plasmid',
            width: 160,
            editable: false,
            valueFormatter: value => value?.name,
          },
          {
            field: 'volume',
            headerName: "μL",
            width: 100,
            editable: true,
            type: "number",
            valueGetter: value => value && value / 1000,
            valueSetter: (value, row) => ({...row, volume: value && value * 1000}),
          },
        ]}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
          columns: {
            columnVisibilityModel: {
              plasmid_packaging: false, // not visible, other cols remain visible
              plasmid_envelope: false,
            },
          },
        }}
        getPrintLabel={(row) => ["LENTIVIRUS", row.name, `Simon ${moment(row.date).format("M/D/YYYY")}`]}
      />
    </>)
}
import React, { useEffect, useState } from 'react';
import { IconButton, LinearProgress } from '@mui/material';
import moment from 'moment';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axios from 'axios';

export default function ThermocyclingStep({row_ids, setData, data: {program, lid_temperature, time_start : _time_start_server, time_estimated, timer_api}}) {
  const [_time_start_local, set_time_start_local] = useState()
  const time_start = (_time_start_server && moment(_time_start_server)) || (_time_start_local && moment(_time_start_local))
  const [currentTime, setCurrentTime] = useState(moment())
  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(moment()), 1000)
    return () => clearInterval(interval)
  }, [])
  return (<>
    {time_start ?
      <LinearProgress
        variant="determinate"
        value={100 * Math.min(1, moment.duration(currentTime - time_start).asSeconds() / time_estimated)}
        role="timer"
      />
      :
      <IconButton
        onClick={() => {
          const datetime_now = moment().toISOString()
          if (timer_api) {
            axios.post(timer_api.url, {id: row_ids, [timer_api.key]: datetime_now})
            setData?.(prev => prev.map(x => row_ids.includes(x.id) ? {...x, [timer_api.key]: datetime_now} : x))  
          }
          set_time_start_local(datetime_now)
        }}
        aria-label="start timer for thermocycler"
      ><PlayArrowIcon /></IconButton>
    }
    {lid_temperature && <>Lid = {lid_temperature}°C</>}
    <ul aria-label="thermocycling protocol">
      {program?.map((stage, stage_index) => (
        <li
          aria-label={`stage ${stage_index + 1}`}
          key={stage_index}
        >
          <span aria-label="number of cycles">{stage.num_cycles || "?"} cycle{stage.num_cycles !== 1 && "s"}</span>
          <ul>
            {stage.steps.map((step, step_index) => (
              <li key={step_index} aria-label={`step ${step_index + 1}`}>{step.temperature || "?"}°C for {step.duration || "?"} sec</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </>)
}
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';

export default function AnnealOligosForm({open, row: rows, onClose, onSubmit, showMessage, ...props}) {
  const [data, setData] = useState({product: {name: "fSPS"}})
  const [options_oligos, setOptionsOligos] = useState()
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    axios.post("/api/wetlab/protocols/anneal/create", data).then(({status}) => status === 200 && showMessage("Anneal oligos saved"))
    onSubmit?.(data)
    handleClose()
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  // Load selected oligos
  useEffect(() => {
    if (rows?.[0])
      setData(prev => ({...prev, oligo1: rows[0], ...prev.oligo1}))
    if (rows?.[1])
      setData(prev => ({...prev, oligo2: rows[1], ...prev.oligo2}))
  }, [rows])
  // Get oligo options
  useEffect(() => {
    axios.get("/api/wetlab/oligo/get").then(({data : {data}}) => setOptionsOligos(data))
  }, [])
  // Get recommended fragment sizes
  useEffect(() => {
    if (!data.oligo1?.id || !data.oligo2?.id)
      return
    axios.get(`/api/wetlab/protocols/anneal/calc_product?oligo1_id=${data.oligo1.id}&oligo2_id=${data.oligo2.id}`).then(({data : {sequence}}) => {
      setData(prev => ({
        ...prev,
        product: {
          ...prev.product,
          sequence,
        },
      }))
    })
  }, [data.oligo1?.id, data.oligo2?.id])
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New Anneal Oligos</DialogTitle>
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Product"
            fullWidth
            variant="standard"
            value={data?.product?.name || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, name: e.target.value}}))}
          />
          <DNASelector
            label="Oligo 1"
            options={options_oligos}
            concentrationUnit="μM"
            onChange={(e, v) => handleChange({oligo1: v})}
            value={data?.oligo1 || null}
          />
          <DNASelector
            label="Oligo 2"
            options={options_oligos}
            concentrationUnit="μM"
            onChange={(e, v) => handleChange({oligo2: v})}
            value={data?.oligo2 || null}
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={data?.product?.description || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, description: e.target.value}}))}
          />
          <TextField
            margin="dense"
            label="Sequence"
            fullWidth
            variant="standard"
            value={data?.product?.sequence || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, sequence: e.target.value}}))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

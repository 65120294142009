import React from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  ListSubheader,
  useMediaQuery,
  IconButton,
  Divider,
} from '@mui/material';
import {
  useMatch,
  useNavigate,
  Outlet
} from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function ObjectSubtypesView({views, matchUrl, mobileOpen, setMobileOpen, ...props}) {
  const drawerWidth = 140
  let matchView = useMatch(matchUrl)
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  return(
    <>
      <Box component="main" sx={{display: "flex", width: "100%", height: "100%", pr: isMobile || !mobileOpen ? "0px" : `${drawerWidth}px`}}>
        <Outlet />
      </Box>
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        open={mobileOpen}
        ModalProps={isMobile ? {keepMounted: true, onClose: () => setMobileOpen(false)} : {}}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        anchor="right"
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
          <List>
            {Object.keys(views).map((header) => (
              <React.Fragment key={header}>
                {header && <ListSubheader>{header}</ListSubheader>}
                {Object.keys(views[header]).map(key => (
                  <ListItem key={key} disablePadding dense>
                    <ListItemButton
                      selected={matchView?.params.type === key}
                      onClick={() => {
                        navigate(key)
                        setMobileOpen?.(false)
                      }}
                    >
                      <ListItemText primary={views[header][key]} sx={{ ml: 2 }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
        </Box>
        <Divider />
        <Box sx={{p: 0.5}}>
          <IconButton onClick={() => setMobileOpen?.(false)}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Drawer>
    </>);
}

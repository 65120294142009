import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from '@mui/material';
import DNASelector from 'components/DNASelector';
import axios from 'axios';
import SampleNameField from 'components/SampleNameField';

export default function PCRForm({open, onClose, onSubmit, showMessage, ...props}) {
  const default_new_pcr = {product: {name: "fSPS"}, num_cycles: 25, polymerase: {id: "neb-q5hs-0", name: "Q5 Hot Start High-Fidelity DNA Polymerase"}}
  const [data, setData] = useState(default_new_pcr)
  const [options_oligos, setOptionsOligos] = useState()
  const [options_dna, setOptionsDna] = useState()
  const [options_plasmids, setOptionsPlasmids] = useState()
  const options_templates = [...(options_dna || []), ...(options_plasmids || [])]
  const [options_polymerases, setOptionsPolymerases] = useState()
  const [manualLength, setManualLength] = useState(false)
  const lengthMismatch = data?.product?.length && data?.product?.sequence && data.product.length !== data.product.sequence.length
  const handleClose = () => {
    onClose?.()
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit?.(data);
    handleClose();
  }
  const handleChange = (changes) => {
    setData(prev => ({...prev, ...changes}))
  }
  // Get recommended Ta
  useEffect(() => {
    if (!data.primer_fwd?.id || !data.primer_rev?.id || !data.polymerase?.id)
      return
    if (data.polymerase.id.slice(0, 4) !== "neb-") {
      console.error("Unrecognized polymerase")
      return
    }
    axios.get(`/api/wetlab/protocols/pcr/params?polymerase=${data.polymerase.id}&primer_fwd=${data.primer_fwd.id}&primer_rev=${data.primer_rev.id}&template=${data.template?.id}`).then(({data : {amplicon_length, temp_anneal, amplicon_sequence}}) => {
      setData(prev => ({
        ...prev,
        product: {
          ...prev.product,
          length: amplicon_length,
          sequence: amplicon_sequence,
        },
        temp_anneal: temp_anneal?.success ? temp_anneal.data.ta.toFixed(0) : prev.temp_anneal
      }))
      setManualLength(false)
    })
  }, [data.primer_fwd?.id, data.primer_rev?.id, data.polymerase?.id, data.template?.id])
  useEffect(() => {
    if (data.product.length)
      setData(prev => ({...prev, time_extend: 30 * Math.ceil(data.product.length / 1000)}))
  }, [data.product?.length])
  // Get primer and template options
  useEffect(() => {
    axios.get("/api/wetlab/oligo/get").then(({data : {data}}) => setOptionsOligos(data))
  }, []);
  useEffect(() => {
    axios.get("/api/wetlab/dna/get").then(({data : {data}}) => setOptionsDna(data))
  }, []);
  useEffect(() => {
    axios.get("/api/wetlab/plasmids/get").then(({data : {data}}) => setOptionsPlasmids(data))
  }, []);
  useEffect(() => {
    axios.get("https://tmapi.neb.com/docs/productcodes").then(({data : {data}}) => setOptionsPolymerases(data.map(x => ({...x, id: "neb-" + x.prodcode, description: "NEB #" + x.catalog}))))
  }, []);
  return (
    <Dialog open={!!open} onClose={onClose} fullWidth {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>New PCR</DialogTitle>
        <DialogContent>
          <SampleNameField
            autoFocus
            margin="dense"
            label="Product"
            fullWidth
            variant="standard"
            value={data?.product?.name || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, name: e.target.value}}))}
          />
          <DNASelector
            label="Forward Primer"
            options={options_oligos}
            concentrationUnit="μM"
            onChange={(e, v) => handleChange({primer_fwd: v})}
            value={data?.primer_fwd || null}
          />
          <DNASelector
            label="Reverse Primer"
            options={options_oligos}
            concentrationUnit="μM"
            onChange={(e, v) => handleChange({primer_rev: v})}
            value={data?.primer_rev || null}
          />
          <DNASelector
            label="Template"
            options={options_templates}
            concentrationUnit="ng/μL"
            onChange={(e, v) => handleChange({template: v})}
            value={data?.template || null}
          />
          <DNASelector
            label="Polymerase"
            options={options_polymerases}
            onChange={(e, v) => handleChange({polymerase: v})}
            value={data?.polymerase || null}
          />
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Anneal"
                fullWidth
                variant="standard"
                value={data?.temp_anneal || ""}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                }}
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                onChange={e => handleChange({temp_anneal: e.target.value})}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Length"
                fullWidth
                variant="standard"
                value={data?.product?.length || ""}
                InputProps={{
                  endAdornment: <InputAdornment position="end"> bp</InputAdornment>,
                }}
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                onChange={e => {
                  const newVal = parseInt(e.target.value) || null
                  setData(prev => ({...prev, product: {...prev.product, length: newVal}}))
                  setManualLength(!!newVal)
                }}
                error={lengthMismatch}
                helperText={lengthMismatch ? `Sequence is ${data.product.sequence.length} bp` : ""}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Extend"
                fullWidth
                variant="standard"
                value={data?.time_extend || ""}
                InputProps={{
                  endAdornment: <InputAdornment position="end"> sec</InputAdornment>,
                }}
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                onChange={e => handleChange({time_extend: e.target.value})}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                margin="dense"
                label="Cycles"
                fullWidth
                variant="standard"
                value={data?.num_cycles || ""}
                inputProps={{inputMode: "numeric"}}
                sx={{input: {textAlign: "right"}}}
                onChange={e => handleChange({num_cycles: e.target.value})}
              />
            </Grid>
          </Grid>
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            variant="standard"
            value={data?.product?.description || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, description: e.target.value}}))}
          />
          <TextField
            margin="dense"
            label="Sequence"
            fullWidth
            variant="standard"
            value={data?.product?.sequence || ""}
            onChange={e => setData(prev => ({...prev, product: {...prev.product, sequence: e.target.value, length: manualLength ? prev.product.length : e.target.value.length}}))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Add</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
